<template>
  <div class="allbox">
    <div class="head_box">
      <div class="toop_box">
        <div class="container top">
          <!-- 未登录title -->
          <div class="row top_text" v-if="!titleshow">
            <div class="to_sampling">
              {{
                enstatus == 1 ? "欢迎来到 Sampling" : "Welcome to Sampling"
              }}&nbsp;&nbsp;&nbsp;{{
                enstatus == 1 ? "你可以选择" : "You can choose"
              }}
              <u style="cursor: pointer" @click="tologin">{{
                enstatus == 1 ? "登录" : "Login"
              }}</u>
              {{ enstatus == 1 ? "或者" : "OR" }}
              <u style="cursor: pointer" @click="tore">{{
                enstatus == 1 ? "注册" : "Register"
              }}</u>
            </div>
            <div class="col-5 inpinfo">
              <div class="ininfo">
                <input
                  v-model="serch"
                  type="text"
                  @keyup.enter="to_serch"
                  name
                  id
                  placeholder="搜你想要的"
                  class="serch_inp"
                  v-if="enstatus == 1"
                />
                <input
                  v-model="serch"
                  type="text"
                  @keyup.enter="to_serch"
                  name
                  id
                  placeholder="Search what you want"
                  class="serch_inp_en"
                  v-if="enstatus == 2"
                />
                <img
                  src="../assets/img/icon_01.png"
                  alt
                  class="ser_img"
                  @click="to_serch"
                  v-if="enstatus == 1"
                />
                <img
                  src="../assets/img/icon_01.png"
                  alt
                  class="ser_img_en"
                  v-if="enstatus == 2"
                  @click="to_serch"
                />
              </div>
            </div>
            <div
              class="cart_img"
              :style="enstatus == 2 ? 'margin-left: 3.8vw;' : ''"
              style="cursor: pointer"
              @click="tocart"
            >
              <img src="../assets/img/icon_02.png" alt />
              {{ enstatus == 1 ? "购物车" : "Shopping cart" }}({{
                detail_info.cart_num
              }})
            </div>
          </div>
          <!-- 已登录title -->
          <div class="row top_text" v-if="titleshow">
            <div class="to_sampling cometo">
              {{ enstatus == 1 ? "欢迎" : "Welcome to" }} {{ username }}
              {{ enstatus == 1 ? "来到 Sampling" : "Sampling" }}
              <div style="cursor: pointer" @click="clear">
                <img src="../assets/img/icon_07.png" alt />
                {{ enstatus == 1 ? "退出登录" : "Log out" }}
              </div>
              <div class="col-5 inpinfo">
                <div class="ininfo">
                  <input
                    v-model="serch"
                    type="text"
                    @keyup.enter="to_serch"
                    name
                    id
                    placeholder="搜你想要的"
                    class="serch_inp"
                    v-if="enstatus == 1"
                  />
                  <input
                    v-model="serch"
                    type="text"
                    @keyup.enter="to_serch"
                    name
                    id
                    placeholder="Search what you want"
                    class="serch_inp_en"
                    v-if="enstatus == 2"
                  />
                  <img
                    src="../assets/img/icon_01.png"
                    alt
                    class="ser_img"
                    @click="to_serch"
                    v-if="enstatus == 1"
                  />
                  <img
                    src="../assets/img/icon_01.png"
                    alt
                    class="ser_img_en"
                    @click="to_serch"
                    v-if="enstatus == 2"
                  />
                </div>
              </div>
            </div>

            <div class="cart_img_l" style="cursor: pointer" @click="tocart">
              <img src="../assets/img/icon_02.png" alt />
              {{ enstatus == 1 ? "购物车" : "Shopping cart" }}({{
                detail_info.cart_num
              }})
            </div>
          </div>
        </div>
        <!-- 手机端搜索 -->
        <div>
          <div class="ininfo more_serch">
            <input
              v-model="serch"
              type="text"
              @keyup.enter="to_serch"
              name
              id
              placeholder="搜你想要的"
              class="serch_inp"
              v-if="enstatus == 1"
            />
            <input
              v-model="serch"
              type="text"
              @keyup.enter="to_serch"
              name
              id
              placeholder="Search what you want"
              class="serch_inp_en"
              v-if="enstatus == 2"
            />
            <img
              src="../assets/img/icon_01.png"
              alt
              class="ser_img"
              @click="to_serch"
              v-if="enstatus == 1"
            />
            <img
              src="../assets/img/icon_01.png"
              alt
              class="ser_img_en"
              v-if="enstatus == 2"
              @click="to_serch"
            />
          </div>
        </div>
      </div>
      <div class="logo_albox">
        <div class="container logo_row">
          <div class="row logo_row">
            <div class="col-3 logo">
              <img
                style="cursor: pointer"
                src="../assets/img/img_03.png"
                @click="toindex"
                alt
                class="logo_img"
              />
            </div>
            <div class="col-1 dnmd"></div>
            <div class="col-6 navInfo">
              <div
                class="font_s"
                @mouseenter="enter"
                @mouseleave="leave"
                @click="toproduct"
                :class="active == 1 ? 'active_l' : ''"
              >
                {{ enstatus == 1 ? "产品" : " Product" }}
                <div class="bordder" v-if="active == 1"></div>
              </div>
              <div
                class="font_s"
                @click="tocart"
                :class="active == 2 ? 'active_l' : ''"
              >
                {{ enstatus == 1 ? "在线询价单" : "RFQ" }}
                <div class="bordder" v-if="active == 2"></div>
              </div>
              <div
                class="font_s"
                @click="tonews"
                @mouseenter="newsenter"
                @mouseleave="newsleave"
                :class="active == 3 ? 'active_l' : ''"
              >
                {{ enstatus == 1 ? "咨询信息" : " Information" }}
                <!-- 边框 -->
                <div class="bordder" v-if="active == 3"></div>
              </div>
              <div
                class="font_s"
                @click="tous"
                :class="active == 4 ? 'active_l' : ''"
              >
                {{ enstatus == 1 ? "联系我们" : "Contact Us" }}
                <div class="bordder" v-if="active == 4"></div>
              </div>
            </div>
            <div class="col-2 my">
              <div class="row" style="height: 100%">
                <div class="active_lan cnbox" style="align-self: center">
                  <div
                    class="chinese_box2"
                    @click="cnboxshow"
                    style="cursor: pointer"
                  >
                    <div
                      v-if="active_lan == 1"
                      class="chinese_box"
                      :class="active_lan == 1 ? 'active_lan' : ''"
                    >
                      <img src="../assets/img/img_01.png" alt />
                      <div>CN</div>
                      <img src="../assets/img/icon_03.png" alt />
                    </div>
                    <div
                      class="chinese_box"
                      v-else
                      :class="active_lan == 1 ? 'active_lan' : ''"
                    >
                      <img src="../assets/img/img_02.png" alt />
                      <div>EN</div>
                      <img src="../assets/img/icon_03.png" alt />
                    </div>
                  </div>
                  <div
                    class="chinese_box1"
                    :class="show ? 'chinese_box11' : ''"
                    style="cursor: pointer"
                  >
                    <div
                      @click="cnboxshow1(1)"
                      v-if="active_lan == 1"
                      class="chinese_box"
                    >
                      <img src="../assets/img/img_02.png" alt />
                      <div>EN</div>
                    </div>
                    <div @click="cnboxshow1(2)" v-else class="chinese_box">
                      <img src="../assets/img/img_01.png" alt />
                      <div>CN</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 弹出层 -->
      <!-- 产品 -->
      <!-- 第一层 true-->
      <div
        class="topbox common"
        v-show="firshow"
        @mouseenter="enter"
        @mouseleave="leave"
      >
        <div
          class="tctop"
          v-for="(item, index) in product_list"
          :key="index"
          @click="changefir(index, item.goods_type_id)"
          :class="fir_tab === index ? 'act' : ''"
          style="cursor: pointer"
        >
          {{ item.goods_type_name }}
        </div>
      </div>

      <!-- 第二层 -->
      <div class="topbox common heheda" v-if="secshow">
        <div
          class="xrtop"
          v-for="(item, index) in sec_list"
          :key="index"
          @click="changesec(index, item.goods_type_id)"
          :class="sec_tab === index ? 'active_t' : ''"
        >
          {{ item.goods_type_name }}
          <div></div>
        </div>
      </div>
      <!-- 第三层 -->
      <div class="topbox common heheda" v-if="thrshow">
        <div
          class="xrtop"
          v-for="(item, index) in thr_list"
          :key="index"
          @click="changethr(index, item.goods_type_id)"
          :class="thr_tab === index ? 'active_t' : ''"
        >
          {{ item.goods_type_name }}
          <div></div>
        </div>
      </div>
      <!-- 第四层 -->
      <div class="topbox common heheda" v-if="forshow">
        <div
          class="xrtop"
          v-for="(item, index) in for_list"
          :key="index"
          @click="changefor(index, item.goods_type_id)"
          :class="for_tab === index ? 'active_t' : ''"
        >
          {{ item.goods_type_name }}
          <div></div>
        </div>
      </div>
      <div v-show="firshow" class="turn_right_more">
        {{
          enstatus == 1 ? "你可以向右滑动查看更多" : "Slide right to see more"
        }}
      </div>
      <!-- 新闻 -->
      <div
        class="topbox common ouhuo"
        v-show="newsshow"
        @mouseenter="newsenter"
        @mouseleave="newsleave"
      >
        <div
          class="tctop"
          v-for="(item, index) in newlist"
          :key="index"
          @click="changetitle(index, item.news_id)"
          :class="title == index ? 'act' : ''"
        >
          {{ item.title }}
        </div>
      </div>
    </div>

    <router-view :lang="active_lan" :lanid="newsid"></router-view>
    <div class="foot_box">
      <div class="container">
        <div class="row">
          <div class="col-9">
            <div class="left_firbox">
              <div>
                {{ enstatus == 1 ? "邮箱" : "Email" }}：{{ detail_info.email }}
              </div>
              <div>
                {{ enstatus == 1 ? "微信号" : "We-Chat" }}：{{
                  detail_info.wechat_num
                }}
              </div>
              <div>
                {{ enstatus == 1 ? "联系电话" : "Phone No" }}：{{
                  detail_info.contact_mobile
                }}
              </div>
            </div>
            <div class="left_secbox">{{ detail_info.copyright }}</div>
          </div>
          <div class="col-3 text_info">
            <img :src="detail_info.wechat_qrcode" alt />
            <div>{{ enstatus == 1 ? "微信二维码" : "Wechat QR code" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
let username = localStorage.getItem("username");
let lan = localStorage.getItem("lan");
import Vue from "vue";
import axios from "axios";
import $ from "jquery";
export default {
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
  data() {
    return {
      thrshow: false,
      forshow: false,
      serch: "",
      show: false,
      username: null,
      titleshow: false,
      active: null,
      tab: 0,
      title: 0,
      firshow: false,
      secshow: false,
      newsshow: false,
      active_lan: 1,
      enstatus: 1,
      detail_info: {},
      newlist: [],
      newsid: "",
      show: false,
      product_list: [],
      sec_list: [],
      thr_list: [],
      for_list: [],
      fir_tab: "",
      sec_tab: "",
      thr_tab: "",
      for_tab: "",
    };
  },
  methods: {
    cnboxshow() {
      this.show = !this.show;
    },
    cnboxshow1(i) {
      this.show = false;
      if (i == 1) {
        this.active_lan = 2;
        this.changelan(2);
      } else {
        this.active_lan = 1;
        this.changelan(1);
      }
    },
    ...mapMutations([
      "changelangui",
      "changenewsid",
      "sent_serch",
      "change_firid", //第一层
      "change_secid", //二
      "change_thrid", //三
      "change_forid", //四
      "change_allid", //全
    ]),
    clear() {
      localStorage.removeItem("username");
      localStorage.removeItem("users_id");
      location.reload();
    },
    // 语言更换
    async changelan(i) {
      console.log(i);
      localStorage.setItem("lan", i);
      this.active_lan = i;
      this.enstatus = i;
      let res = await axios.post(Vue.prototype.baseURL + `Index/newsTypeList`, {
        lang_type: i,
      });
      // console.log(res);
      this.changelangui(i);
      this.newlist = res.data.data;
      this.newsid = res.data.data[0].news_id;
    },
    toindex() {
      this.$router.push("/");
    },
    tore() {
      this.$router.push("/login");
    },
    tologin() {
      this.$router.push("/re");
    },
    newsenter() {
      if (this.$route.path == "/news") {
        this.newsshow = true;
      }
    },
    newsleave() {
      this.newsshow = false;
    },
    changetitle(v, id) {
      // console.log(v, id);
      this.title = v;
      if (this.$route.path == "/product") {
        this.secshow = true;
      }
      this.changenewsid(id);
      // this.newsid = id;
    },
    //点击第一层，获取第二层
    async changefir(v, id) {
      this.fir_tab = v;
      if (this.$route.path == "/product") {
        this.secshow = true;
      }
      let res = await axios.post(
        Vue.prototype.baseURL + `Index/goodsTypeList`,
        {
          lang_type: this.lan,
          pid: id,
        }
      );
      console.log(res);
      this.sec_list = res.data.data;
      // this.tab = 0;
      this.sec_tab = "";
      this.change_firid(id);
      this.change_allid(id);
    },
    changetab(v) {
      this.tab = v;
    },
    //点击第二层，获取第三层
    async changesec(v, id) {
      this.sec_tab = v;
      let res = await axios.post(
        Vue.prototype.baseURL + `Index/goodsTypeList`,
        {
          lang_type: this.lan,
          pid: id,
        }
      );
      console.log(res);
      this.thr_list = res.data.data;
      if (res.data.data.length == 0) {
        this.thrshow = false;
      } else {
        this.thrshow = true;
      }
      // this.thr_tab = 0;
      this.thr_tab = "";
      this.change_secid(id);
      this.change_allid(id);
    },
    //点击第三层，获取第四层
    async changethr(v, id) {
      this.thr_tab = v;
      let res = await axios.post(
        Vue.prototype.baseURL + `Index/goodsTypeList`,
        {
          lang_type: this.lan,
          pid: id,
        }
      );
      console.log(res);
      this.for_list = res.data.data;
      if (res.data.data.length == 0) {
        this.forshow = false;
      } else {
        this.forshow = true;
      }
      this.for_tab = "";
      this.change_thrid(id);
      // this.for_tab = 0;
      this.change_allid(id);
    },
    //第四层
    changefor(v, id) {
      this.for_tab = v;
      this.change_forid(id);
      this.change_allid(id);
    },
    enter() {
      if (this.$route.path == "/product") {
        // console.log(11111);
        this.firshow = true;
      }
    },
    leave() {
      this.firshow = false;
    },
    to_serch() {
      this.sent_serch(this.serch);
      if (this.serch !== "") {
        // console.log(this.$route.path);
        if (this.$route.path != "/search") {
          this.$router.push("/search");
        }
      }
    },
    tonews() {
      this.$router.push("/news");
      this.newsenter();
    },
    toproduct() {
      this.$router.push("/product");
      this.enter();
    },
    tocart() {
      this.$router.push("/cart");
    },
    tous() {
      this.$router.push("/us");
    },
  },
  updated() {
    if (username !== null) {
      this.titleshow = true;
      // console.log(this.titleshow);
      this.username = username;
    }
    let path = this.$route.fullPath;
    if (path == "/news") {
      this.active = 3;
    }
  },
  async created() {
    // console.log(username);
    if (username !== null) {
      this.titleshow = true;
      // console.log(this.titleshow);
      this.username = username;
    }
    this.changelan(lan);
    // this.changelangui(lan);
    let path = this.$route.fullPath;
    if (path !== "product") {
      this.secshow = false;
    }
    if (path == "/news") {
      this.active = 3;
      this.firshow = false;
    }
    if (path == "/product") {
      this.active = 1;
    }
    if (path == "/cart") {
      this.active = 2;
      this.firshow = false;
    }
    if (path == "/us") {
      this.active = 4;
      this.firshow = false;
    }
    if (
      path !== "/news" &&
      path !== "/product" &&
      path !== "/cart" &&
      path !== "/us"
    ) {
      this.active = 0;
    }
    let users_id = localStorage.getItem("users_id");
    if (users_id == null || "") {
      users_id = 0;
    }
    let res = await axios.post(Vue.prototype.baseURL + `Index/common`, {
      users_id: users_id,
    });
    // console.log(res);
    this.detail_info = res.data.data;
    console.log(res);
    let req = await axios.post(Vue.prototype.baseURL + `Index/newsTypeList`, {
      lang_type: lan,
    });
    // console.log(req);
    // 初次渲染 渲染咨询信息
    this.changetitle(0, req.data.data[0].news_id);
    let reqq = await axios.post(Vue.prototype.baseURL + `Index/goodsTypeList`, {
      lang_type: this.lan,
      pid: 0,
    });
    console.log(reqq);
    this.product_list = reqq.data.data;
  },
  watch: {
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/search`, {
        lang_type: lan,
      });
      this.firshow = false;
      this.secshow = false;
      this.thrshow = false;
      this.forshow = false;
      let req = await axios.post(
        Vue.prototype.baseURL + `Index/goodsTypeList`,
        {
          lang_type: this.lan,
          pid: 0,
        }
      );
      this.tab = 0;
      this.product_list = req.data.data;
    },
    $route(to, from) {
      console.log(to.path);
      if (to.path !== "product") {
        this.secshow = false;
      }
      if (to.path == "/news") {
        this.active = 3;
      }
      if (from.path == "/re") {
        location.reload();
      }
      if (to.path == "/") {
        this.active = 0;
        this.firshow = false;
      }
      if (to.path == "/product") {
        this.active = 1;
        this.fir_tab = "";
      }
      if (to.path == "/cart") {
        this.active = 2;
        this.firshow = false;
      }
      if (to.path == "/us") {
        this.active = 4;
        this.firshow = false;
      }
      if (to.path == "/search") {
        this.active = 0;
        this.firshow = false;
      }
      if (to.path == "/login") {
        this.active = 0;
        this.firshow = false;
      }
      if (to.path == "/re") {
        this.active = 0;
        this.firshow = false;
      }
    },
  },
};
</script>

<style lang='scss'>
.turn_right_more {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #666;
}
.more_serch {
  display: none;
}
.chinese_box2 {
  position: relative !important;
  z-index: 5;
  background: #d4d4d4;
}
.cnbox {
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
}
.chinese_box {
  box-sizing: border-box;
}
.english_box {
  float: right;
}

.chinese_box1 {
  position: absolute;
  top: 0;
  z-index: 0;
  transition: all 0.5s;
}
.chinese_box11 {
  transform: translateY(23px);
  position: absolute;
  // z-index: 99;
}
@media screen and (max-width: 800px) {
  .turn_right_more {
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
  .more_serch {
    display: block !important;
    width: 100% !important;
  }

  .toop_box {
    padding-bottom: 10px !important;
  }
  .serch_inp_en {
    width: 91% !important;
    height: 40px !important;
    border-radius: 20px !important;
    line-height: 40px !important;
  }
  .ser_img {
    width: 20px !important;
    height: 20px !important;
    top: 12px !important;
    left: 85% !important;
  }
  .ser_img_en {
    width: 20px !important;
    height: 20px !important;
    top: 12px !important;
    left: 85% !important;
  }
  .serch_inp {
    width: 91% !important;
    height: 40px !important;
    border-radius: 20px !important;
    line-height: 40px !important;
  }
  .navInfo {
    margin-left: 2%;
  }
  .logo_img {
    margin-left: 13%;
  }
  .cometo > div > img {
    width: 11px !important;
    display: inline-block;
    margin-bottom: 3px;
  }
  .chinese_box2 {
    margin-left: 10vw;
  }
  .chinese_box1 {
    box-sizing: border-box;
    left: 10vw;
    padding-left: 4%;
  }
  .my {
    padding-right: 0 !important ;
    margin-left: 5vw;
  }
  .dnmd {
    display: none;
  }
  .text_info > img {
    height: 16.6vw !important;
    width: 16.6vw !important;
  }
  .text_info > div {
    width: 16.6vw !important;
    overflow: visible !important;
    text-overflow: clip !important;
    white-space: normal !important;
    line-height: 4.6vw !important;
    font-size: 3.2vw !important;
  }

  .left_secbox {
    line-height: 9.7vw !important;
    font-size: 2vw !important;
  }
  .foot_box {
    min-height: 26.26vw !important;
    width: 100%;
    background: #333333;
  }
  .left_firbox > div {
    line-height: 7.5vw !important;
    font-size: 3.6vw !important;
  }
  .left_firbox {
    min-height: 10vw !important;
    width: 100%;
    padding-top: 1.2vw;
    padding-left: 1vw;
    border-bottom: 1px solid #5c5c5c;
  }
  .tctop {
    font-size: 2.5vw !important;
    // width: 20% !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    // float: left;
  }
  .xrtop {
    font-size: 2.5vw !important;
    // width: 20% !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    // float: left;
  }
  .navInfo {
    padding: 0 !important;
  }
  .navInfo > div {
    font-size: 3vw !important;
  }
  .my {
    // padding-top: 5vw !important;
  }
  .chinese_box {
    width: 9vw !important;
    margin-bottom: 1vw;
    height: 3vw !important;
  }
  .chinese_box > div {
    line-height: 3.5vw !important;
  }
  .chinese_box > img:nth-of-type(1) {
    width: 4vw !important;
    height: 3vw !important;
  }
  .chinese_box > img:nth-of-type(2) {
    display: none !important;
  }
  .english_box {
    width: 9vw !important;
    margin-bottom: 1vw;
    height: 3vw !important;
  }
  .english_box > div {
    line-height: 3.5vw !important;
  }
  .english_box > img:nth-of-type(2) {
    display: none !important;
  }
  .english_box > img:nth-of-type(1) {
    width: 4vw !important;
    height: 3vw !important;
  }
  .cart_img {
    display: inline-block;
    margin-left: 1.8vw !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px !important;
  }
  .cart_img_l {
    display: inline-block;
    margin-left: -0.2vw !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px !important;
  }
  .cart_img_l > img {
    display: inline-block;
    height: 3vw !important;
    width: 3vw !important;
    margin-bottom: 0.3vh !important;
  }
  .cart_img > img {
    display: inline-block;
    height: 3vw !important;
    width: 3vw !important;
    margin-bottom: 0.8vh;
  }
  .logo_row {
    line-height: 18vw !important;
  }
  .top_text {
    line-height: 12.6vw !important;
    // font-size: 12px !important;
  }
  .inpinfo {
    display: none !important;
  }
  .to_sampling {
    margin-left: 5vw;
    width: 64vw !important;
    font-size: 3vw !important;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .col2 {
    flex: none !important;
    max-width: none !important;
  }

  .font_s:nth-of-type(2) {
    margin-left: 5%;
    margin-right: 7%;
  }
  .font_s:nth-of-type(3) {
    // min-width: 35% !important;
    // margin-left: 5%;
    margin-right: 7%;
  }
}
.ouhuo {
  position: relative;
  z-index: 1;
}
.heheda {
  background: #f8f8f8 !important;
}
.cometo > div > img {
  width: 15px;
  display: inline-block;
  margin-bottom: 3px;
}
.cometo > div {
  display: inline-block;
  margin-left: 5px;
}
.topbox > .xrtop {
  // overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // width: 100%;
  // flex: 1;
  // width: 15%;
  padding: 0 50px 0 50px;
  // float: left;
  text-align: center;
  display: inline-block;
  font-size: 1.2vw;
  cursor: pointer;
}
.topbox > .tctop {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 6.9vh;
  // flex: 1;
  // float: left;
  // width: 200px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 1.2vw;
  background: #d7e6e3;
  display: inline-block;
  color: #666;
  // white-space: nowrap;
}
.xrtop > div {
  height: 2px;
  width: 1.05vw;
  margin: auto;
  background: transparent;
}
.topbox {
  white-space: nowrap;
  width: 100%;
  background: #d7e6e3;
  // height: 6.9vh;
  // overflow-x: scroll;
  overflow-x: auto;
  margin-top: -5px;
  // overflow-y: hidden;
}
.act {
  color: #008067 !important;
}
.topbox {
  // line-height: 6vh;
  // display: flex;
  width: 100%;
  background: #d7e6e3;
}
.active_t {
  color: #008067;
}
.active_t > div {
  height: 2px;
  width: 1.05vw;
  margin: auto;
  background: #008067;
}
.font_s {
  position: relative;
  text-align: center;
  font-weight: 520;
  cursor: pointer;
}
// .bordder {
//   width: 1.5vw;
//   height: 0.3vw;
//   background: #0e886f;
//   border-radius: 0.15vw;
//   margin-left:3.6vw ;
// }
.cart_img_l {
  display: inline-block;
  margin-left: 11.8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cart_img_l > img {
  display: inline-block;
  height: 1.2vw;
  width: 1vw;
  margin-bottom: 0.8vh;
}
.active_l {
  color: #0e886f;
  border-bottom: 3px solid #0e886f;
}
.active_l:hover {
  background: #d7e6e3;
}

.active_l:hover .child {
  position: absolute;
  z-index: 0;
  bottom: -2.2vw;
  height: 2vw;
  width: 74vw;
  background: #d7e6e3;
}

.ininfo {
  position: relative;
  width: 31.5vw;
}
.top {
  padding-top: 0 !important;
}
.top_text {
  line-height: 3.6vw;
  font-size: 1vw;
  color: #fffdfe;
}
.my {
  // padding-right: 0 !important;
  padding-left: 0 !important;
  width: 7vw !important;
  float: right;
}
.active_lan {
}
.chinese_box {
  display: flex;
  // width: 6vw;
  // height: 2vw;
  line-height: 2vw;
  overflow: hidden;
  padding-left: 0.3vw;
  padding-right: 0.3vw;
  // margin-top: 2.5vw;
}
.chinese_box > img:nth-of-type(1) {
  display: inline-block;
  height: 0.9vw;
  width: 1.3vw;
  align-self: center;

  // margin-top: 0.23vw;
  margin-right: 0.2vw;
}
.chinese_box > div {
  display: inline-block;
  height: 1.5vw;
  line-height: 1.4vw;
  font-size: 1vw;
}
.chinese_box > img:nth-of-type(2) {
  display: inline-block;
  // margin-top: 1vw;
  margin-left: 0.3vw;
  height: 0.4vw;
  width: 0.5vw;
  align-self: center;
}
.english_box {
  display: flex;
  width: 6vw;
  height: 2vw;
  line-height: 2vw;
  overflow: hidden;
  padding-left: 0.8vw;
  margin-top: 0.1vw;
}
.english_box > img:nth-of-type(1) {
  display: inline-block;
  height: 1.45vw;
  width: 2.3vw;
  margin-top: 0.23vw;
  margin-right: 0.2vw;
}
.english_box > div {
  display: inline-block;
  height: 2vw;
  line-height: 2vw;
  font-size: 1.2vw;
}
.english_box > img:nth-of-type(2) {
  display: inline-block;
  margin-top: 1vw;
  margin-left: 0.3vw;
  height: 0.36vw;
  width: 0.54vw;
}
.navInfo > div {
  flex: 1;
  font-size: 1.2vw;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navInfo {
  display: flex;
  width: 100%;
}
.logo_albox {
  width: 100%;
  background: #f0f0f0;
}
.logo_row {
  // height: 7vw;
  line-height: 7vw;
  background: #f0f0f0;
  // padding-left: 0 !important;
  // padding-right: 0 !important;
}
.logo_img {
  // width: 17vw;
  width: auto !important;
  height: 4.3vw;
  display: inline-block;
}
.changechinese > div {
  display: inline-block;
  height: 3vh;
  width: 8vw;
  line-height: 0;
  text-align: center;
}
.changechinese > div > img:nth-of-type(1) {
  display: inline-block;
  width: 1.5vw;
  line-height: 100%;
}
.changechinese {
  // float: right;
  width: 2vw;
  display: inline-block;
}
.info_box {
  display: inline-block;
  // display: flex;
  margin-left: 3vw;
  width: 47vw;
}
.info_box > div {
  display: inline-block;
  font-size: 1.3vw;
  width: 8vw;
  text-align: center;
}
.nav_box {
  height: 16vh;
  width: 100%;
  background: #f0f0f0;
  line-height: 16vh;
}
.index_logo {
  width: 18vw;
  height: 8.8vh;
  display: inline-block;
}
.cart_img {
  display: inline-block;
  margin-left: 8.8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cart_img > img {
  display: inline-block;
  height: 1.2vw;
  width: 1vw;
  margin-bottom: 0.8vh;
}
.inpinfo {
  display: inline-block;
  position: relative;
  line-height: 2.6vw;
  padding-left: 0 !important ;
  padding-right: 0 !important ;
}
.ser_img {
  position: absolute;
  width: 1.2vw;
  height: 1.2vw;
  top: 1.12vw;
  right: -3.5vw;
}
.ser_img_en {
  position: absolute;
  width: 1.2vw;
  height: 1.2vw;
  top: 1.12vw;
  right: 6.5vw;
}
.mytop {
  max-height: 50px;
  display: inline-block;
}
.toop_box {
  width: 100%;
  background: #008167;
}
.head_top_box {
  background: #008167 !important;
  line-height: 6.9vh;
  font-size: 1.1vw;
  color: #ffffff;
}

.head_top_box .bg-info {
  background: #008167 !important;
}
.serch_inp {
  margin-left: 5vw;
  width: 30.7vw;
  display: inline-block;
  // margin-left: 5vw;
  height: 2vw;
  border-radius: 1vw;
  border: 1px solid #b3e8de;
  padding-left: 2vw;
  color: #ffffff;
  background: #008167;
  margin-top: 0.8vw;
  line-height: 2vw;
}
.serch_inp_en {
  line-height: 2vw;
  margin-left: 5vw;
  width: 20.7vw;
  display: inline-block;
  // margin-left: 5vw;
  height: 2vw;
  border-radius: 1vw;
  border: 1px solid #b3e8de;
  padding-left: 2vw;
  color: #ffffff;
  background: #008167;
  margin-top: 0.8vw;
}
.serch_inp_en::placeholder {
  color: #a8e1d8;
}
.serch_inp::placeholder {
  color: #a8e1d8;
}

.top_serch {
  width: 100%;
  background: #018168;
  height: 3.5vh;
}
.top_login {
  padding-left: 1.5vw;
  line-height: 3.5vh;
  font-size: 1vw;
  display: inline-block;
  color: #ffffff;
}
.left_firbox {
  // height: 10vw;
  width: 100%;
  padding-top: 1.2vw;
  padding-left: 1vw;
  border-bottom: 1px solid #5c5c5c;
}
.text_info {
  padding-top: 3vw;
}
.text_info > img {
  width: 7.6vw;
  height: 7.6vw;
}
.text_info > div {
  width: 7.6vw;
  // height: 2.4vw;
  line-height: 2.4vw;
  text-align: center;
  font-size: 0.84vw;
  color: #888d93;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left_secbox {
  // line-height: 1.5vw;
  color: #c1c5cc;
  font-size: 1vw;
  line-height: 4.7vw;
}
.left_firbox > div {
  color: #c1c5cc;
  font-size: 1vw;
  line-height: 2.5vw;
}
.container {
  padding: 0;
  padding-top: 0.5vw;
}
.foot_box {
  min-height: 15.26vw;
  width: 100%;
  background: #333333;
}
</style>