import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login'
import head from '../components/head'
// import foot from '../components/foot'
import success from '../views/success'
import news from '../views/news'
import product from '../views/product'
import detail from '../views/detail'
import cart from '../views/cart'
import us from '../views/us'
import index from '../views/index'
import search from '../views/serch'
import re from '../views/re'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'head',
  component: head,
  children: [{
      path: '/',
      component: index
    }, {
      path: 'login',
      name: 'login',
      component: login,
    },
    {
      path: 'success',
      name: 'success',
      component: success,

    }, {
      path: 'news',
      name: 'news',
      component: news

    }, {
      path: 'product',
      name: 'product',
      component: product,
    }, {
      path: 'detail/:id',
      name: 'detail',
      component: detail
    }, {
      path: 'cart',
      name: 'cart',
      component: cart,

    }, {
      path: 'us',
      name: 'us',
      component: us,
    }, {
      path: 'search',
      name: 'search',
      component: search,
    },
    {
      path: 're',
      name: 're',
      component: re,
    },
  ],
}]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router