<template>
  <!--  fir_eye-->
  <div class="index_allBox">
    <div class="common ts">
      <b-alert v-model="issame" variant="danger">{{message}}</b-alert>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 userwl">{{lan==1?'账号注册':'Account registration'}}</div>
        <div class="col-2"></div>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 inpinfo_l">
          <div class="user_name">
            <div>{{lan==1?"用户名":'username'}}</div>
            <input type="text" placeholder="请输入您的用户名" v-model="user_name" v-if="lan==1" />
            <input type="text" placeholder="Enter your user name" v-model="user_name" v-if="lan==2" />
          </div>
          <div class="user_name">
            <div>{{lan==1?'用户密码':'User password'}}</div>
            <input
              type="password"
              placeholder="请输入您的用户密码"
              v-if="fir_eye&&lan==1"
              v-model="fir_password"
            />
            <input
              type="password"
              placeholder="Enter your password"
              v-if="fir_eye&&lan==2"
              v-model="fir_password"
            />
            <input
              type="text"
              placeholder="请输入您的用户密码"
              v-if="!fir_eye&&lan==1"
              v-model="fir_password"
            />
            <input
              type="text"
              placeholder="Enter your password"
              v-if="!fir_eye&&lan==2"
              v-model="fir_password"
            />
            <img src="../assets/img/icon_04.png" alt v-if="fir_eye" @click="fir_eye=!fir_eye" />
            <img src="../assets/img/icon_05.png" alt v-if="!fir_eye" @click="fir_eye=!fir_eye" />
          </div>
          <div class="user_name">
            <div>{{lan==1?'确认密码':'Confirm password'}}</div>
            <input
              type="password"
              placeholder="请再次输入您的用户密码"
              v-if="sec_eye&&lan==1"
              v-model="sec_password"
            />
            <input
              type="password"
              placeholder="Enter password again"
              v-if="sec_eye&&lan==2"
              v-model="sec_password"
            />
            <input
              type="text"
              placeholder="请再次输入您的用户密码"
              v-if="!sec_eye&&lan==1"
              v-model="sec_password"
            />
            <input
              type="text"
              placeholder="Enter password again"
              v-if="!sec_eye&&lan==2"
              v-model="sec_password"
            />
            <img src="../assets/img/icon_04.png" alt v-if="sec_eye" @click="sec_eye=!sec_eye" />
            <img src="../assets/img/icon_05.png" alt v-if="!sec_eye" @click="sec_eye=!sec_eye" />
          </div>
          <div class="btn_1" @click="sendre">{{lan==1?'注册':'register'}}</div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
export default {
  props: ["active_lan"],
  data() {
    return {
      fir_eye: true,
      fir_password: "",
      sec_eye: true,
      sec_password: "",
      user_name: "",
      issame: false,
      message: "您的密码不一致",
    };
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
  methods: {
    async sendre() {
      if (this.lan == 1) {
        if (this.fir_password != this.sec_password) {
          this.message = "您的密码不一致";
          this.issame = true;
          let that = this;
          setTimeout(() => {
            that.issame = false;
          }, 2000);
          return false;
        }
      } else if (this.lan == 2) {
        if (this.fir_password != this.sec_password) {
          this.message = "Your passwords don't match";
          this.issame = true;
          let that = this;
          setTimeout(() => {
            that.issame = false;
          }, 2000);
          return false;
        }
      }

      let res = await axios.post(Vue.prototype.baseURL + `Index/register`, {
        account: this.user_name,
        password: this.fir_password,
      });
      console.log(res);
      if (res.data.code == 0) {
        this.issame = true;
        this.message = res.data.message;
        let that = this;
        setTimeout(() => {
          that.issame = false;
        }, 2000);
      }
      if (res.data.code == 1) {
        this.$router.push("/success");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@media screen and (max-width: 800px) {
  .userwl {
    font-size: 3.2vw !important;
  }
  .user_name {
    width: 40vw !important;
  }
  .user_name > div {
    line-height: 6vw !important;
    font-size: 3vw !important;
  }
  .user_name > input {
    height: 9.1vw !important;
    font-size: 3.5vw !important ;
  }
  .user_name > input::placeholder {
    font-size: 3.5vw !important;
  }
  .user_name > img {
    width: 2.6vw !important;
    height: 1.6vw !important;
    bottom: 3.2vw !important;
  }
  .btn_1 {
    line-height: 6.6vw !important;
    font-size: 3.3vw !important;
  }
}
.detail {
  text-align: center;
  font-size: 1.5vw;
  color: #ce0743;
  margin-top: 20px;
}
.btn_1 {
  display: block;
  width: 13.5vw;
  margin: 2.5vw auto;
  text-align: center;
  line-height: 2.6vw;
  font-size: 1vw;
  color: #f8f8f8;
  background: #008067;
  border-radius: 1.3vw;
}
.user_name {
  width: 19.8vw;
  margin: 0 auto;
  color: #656565;
  position: relative;
}
.user_name > img {
  position: absolute;
  bottom: 1.2vw;
  right: 1vw;
  width: 1.2vw;
  height: 0.6vw;
}
.user_name > div {
  font-size: 1.2vw;
  line-height: 3vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user_name > input {
  width: 100%;
  height: 3.1vw;
  // border: none;
  border-radius: 0.26vw;
  background: #f0f0f0;
  font-size: 1vw;
  border: 1px solid #f8f8f8;
  padding-left: 1vw;
}
.user_name > input:focus {
  border: 1px solid #008067;
}
.user_name > input::placeholder {
  font-size: 1vw;
}
.inpinfo_l {
  // height: 28vw;
  margin-bottom: 10vw;
  background: #fff;
  padding: 1.8vw 0;
}
.index_allBox {
  // height: 102vh;
  padding-top: 3.7vw;
  background: #f8f8f8;
}
.userwl {
  margin-bottom: 2vw;
  font-size: 1.2vw;
  color: #018168;
  font-weight: 600;
}
</style>