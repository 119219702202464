<template>
  <div class="index_allbox">
    <div class="common">
      <b-carousel
        id="carousel-10"
        v-model="slide"
        :interval="4000"
        indicators
        background="#ababab"
        img-width="1200"
        img-height="400"
        style="text-shadow: 1px 1px 2px #333"
      >
        <b-carousel-slide
          v-for="(item, index) in banner_list"
          :key="index"
          :img-src="item.image_path"
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <div class="tabimg common">
      <div class="index_img_info">
        <div>
          <img src="../assets/img/img_14.png" alt />
        </div>
        <div>
          <img src="../assets/img/img_15.png" alt />
        </div>
        <div>
          <img src="../assets/img/img_16.png" alt />
        </div>
        <div>
          <img src="../assets/img/img_17.png" alt />
        </div>
      </div>
    </div>
    <div class="index_newthing">
      <div class="index_title">
        {{ lan == 1 ? "新产品" : "New Product" }}
      </div>
      <div class="index_thing">
        <div v-for="(item, index) in good_list" :key="index">
          <img :src="item.goods_logo" alt @click="gotodetail(item.goods_id)" />
          <div class="infonext_box_l">
            <div>{{ item.goods_name }}</div>
            <div @click="takejjinfo(item.goods_intro)">
              {{ item.goods_intro }}
            </div>
            <div @click="gotodetail(item.goods_id)">
              {{ lan == 1 ? "查看详情" : "View details" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zzc" v-if="jjinfoshow" @click="jjinfoshow = false">
      <div class="zzcinfo">
        {{ jjinfo }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      slide: 0,
      jjinfoshow: false,
      jjinfo: "",
      banner_list: [],
      good_list: [],
    };
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
  methods: {
    gotodetail(id) {
      this.$router.push(`/detail/${id}`);
    },
    takejjinfo(info) {
      this.jjinfoshow = true;
      this.jjinfo = info;
    },
  },
  async created() {
    let res = await axios.post(Vue.prototype.baseURL + `Index/webIndex`, {
      lang_type: this.lan,
    });
    console.log(res);
    this.banner_list = res.data.data.banner_list;
    this.good_list = res.data.data.good_list;
  },
  watch: {
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/webIndex`, {
        lang_type: this.lan,
      });
      this.banner_list = res.data.data.banner_list;
      this.good_list = res.data.data.good_list;
    },
  },
};
</script>
<style lang="scss">
.zzcinfo {
  width: 500px;
  height: 600px;
  margin: 100px auto;
  background: #ffffff;
  font-size: 1vw;
  padding: 60px;
}
.zzc {
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 800px) {
  .zzcinfo {
    width: 300px;
    height: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #ffffff;
    font-size: 3.5vw;
    padding: 60px;
  }
  .ininfo {
    display: none;
  }
  .index_img_info {
    width: 90%;
    margin: auto;
  }
  .index_title {
    padding-left: 5%;
    padding-top: 10px;
    background: #f8f8f8;
    font-size: 3.5vw !important;
  }
  .index_thing > div {
    margin-bottom: 3vw;
    width: 45vw !important;
    // height: 390px;
    height: 50vw !important;
    background: #fff;
    border: 1px solid #ededed;
  }
  .infonext_box_l > div:nth-of-type(1) {
    line-height: 4.5vw !important;
    font-size: 3vw !important;
  }
  .infonext_box_l > div:nth-of-type(2) {
    line-height: 3.5vw !important;
    font-size: 3vw !important;
  }
  .infonext_box_l > div:nth-of-type(3) {
    font-size: 3vw !important;
    line-height: 4.5vw !important;
    width: 16vw !important;
    background: #008067;
    text-align: center;
    border-radius: 0.75vw;
    color: #fff;
    position: absolute;
    left: 1vw;
    bottom: 1vw;
  }
}
// .topbox > .xrtop {
//   width: 100%;
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
// }
// .topbox > .tctop {
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
//   background: #d7e6e3;
//   color: #666;
// }
.infonext_box_l > div:nth-of-type(3) {
  cursor: pointer;
  font-size: 1vw;
  line-height: 1.5vw;
  width: 6vw;
  background: #008067;
  text-align: center;
  border-radius: 0.75vw;
  color: #fff;
  position: absolute;
  left: 1vw;
  bottom: 1vw;
}
.infonext_box_l > div:nth-of-type(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.8vw;
  font-size: 1vw;
}
.infonext_box_l > div:nth-of-type(2) {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  height: 3.6vw;
  line-height: 1.2vw;
  font-size: 1vw;
  color: #999;
  -webkit-box-orient: vertical;
}
.infonext_box_l {
  padding: 1vw;
  height: 45%;
  position: relative;
}
.index_thing {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;

  background: #f8f8f8;
  padding: 1.9vw 1.8vw;
}
.index_thing > div > img {
  width: 100%;
  height: 55%;
  border-bottom: 1px solid #ededed;
}
.index_thing > div {
  cursor: pointer;
  margin-left: 7px;
  margin-bottom: 3vw;
  width: 290px;
  height: 390px;
  background: #fff;
  border: 1px solid #ededed;
}
.index_newthing {
  max-width: 980px;
  margin: auto;
}
.index_title {
  line-height: 3vw;
  font-size: 1.2vw;
}
.index_img_info {
  display: flex;
  justify-content: space-around;
}
.index_img_info > div {
  display: block;
  flex: 1;
}
.index_img_info > div > img {
  margin: 2vw auto;
  width: 12vw;
  height: 12vw;
}
#carousel-10 .carousel-indicators li {
  border-radius: 3px;
}
.index_allbox {
}
</style>