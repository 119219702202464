<template>
  <div class="cart_box">
    <div class="common ts">
      <b-alert v-model="sucsend" variant="success">{{ sucmsg }}</b-alert>
    </div>
    <div class="common ts">
      <b-alert v-model="failsend" variant="success">{{ failmsg }}</b-alert>
    </div>
    <!-- 飞出来一坨 -->
    <div class="cart_xjd" @click.stop="showfalse" v-if="shadowshow">
      <div class="cart_cent" @click.stop="shadowshow = true">
        <div class="title">
          {{ lan == 1 ? "生成询价单" : "Generate inquiry sheet" }}
        </div>
        <input
          type="text"
          name
          id
          placeholder="请输入您的真实姓名"
          v-if="lan == 1"
          v-model="name"
        />
        <input
          type="text"
          name
          id
          placeholder="Enter your real name"
          v-if="lan == 2"
          v-model="name"
        />
        <input
          type="text"
          name
          id
          placeholder="请输入您的真实电话号码"
          v-if="lan == 1"
          v-model="number"
        />
        <input
          type="text"
          name
          id
          placeholder="Enter your real phone no"
          v-if="lan == 2"
          v-model="number"
        />
        <input
          type="text"
          name
          id
          placeholder="请输入您的真实微信号"
          v-if="lan == 1"
          v-model="wechect"
        />
        <input
          type="text"
          name
          id
          placeholder="Enter your real Wechat"
          v-if="lan == 2"
          v-model="wechect"
        />
        <div v-if="error" class="error">
          {{ lan == 1 ? "您的信息不可为空" : "Information error" }}
        </div>
        <div class="enter_btn" @click.stop="sendfile">
          {{ lan == 1 ? "生成询价单" : "Generate" }}
        </div>
      </div>
    </div>
    <div class="cartcenter common">
      <ul>
        <li>{{ lan == 1 ? "询价单状态" : "State" }}</li>
        <li
          class="hoverli"
          @click="check(0)"
          :class="active == 0 ? 'active_li' : ''"
        >
          {{ lan == 1 ? "待生成" : "Generated" }}
        </li>
        <li
          class="hoverli"
          @click="check(1)"
          :class="active == 1 ? 'active_li' : ''"
        >
          {{ lan == 1 ? "待发送" : "Not sent" }}
        </li>
        <li
          class="hoverli"
          @click="check(2)"
          :class="active == 2 ? 'active_li' : ''"
        >
          {{ lan == 1 ? "已发送" : "Been sent" }}
        </li>
      </ul>
      <div class="right_box" v-if="active == 0">
        <div class="right_top">
          <div>{{ lan == 1 ? "选择" : "Choice" }}</div>
          <div>{{ lan == 1 ? "图片" : "Pic" }}</div>
          <div>{{ lan == 1 ? "名称" : "Name" }}</div>
          <div>{{ lan == 1 ? "零件号" : "Part number" }}</div>
          <div>{{ lan == 1 ? "数量" : "Number" }}</div>
          <div>{{ lan == 1 ? "操作" : "Edit" }}</div>
        </div>
        <div class="right_body" v-for="(item, index) in fir_info" :key="index">
          <div>
            <img
              src="../assets/img/icon_09.png"
              alt
              v-if="!item.ischeck"
              @click="item.ischeck = !item.ischeck"
            />
            <img
              src="../assets/img/icon_08.png"
              v-if="item.ischeck"
              alt
              @click="item.ischeck = !item.ischeck"
            />
          </div>
          <div>
            <img :src="item.goods_logo" alt />
          </div>
          <div>{{ item.goods_name }}</div>
          <div>{{ item.specification_value }}</div>
          <div>
            <input
              type="number"
              v-model="item.num"
              @blur="sendnum(item.cart_id, item.num)"
            />
          </div>
          <div>
            <img
              src="../assets/img/icon_10.png"
              alt
              @click="delthisone(item.cart_id, 0)"
            />
          </div>
        </div>
      </div>
      <div
        class="scbtn"
        @click="changeshow"
        v-if="active == 0 && fir_info.length > 0"
      >
        {{ lan == 1 ? "生成询价单" : "Generate" }}
      </div>
      <div class="right_box_l" v-if="active == 1">
        <div class="right_top_l">
          <div>{{ lan == 1 ? "选择" : "Choice" }}</div>
          <div>{{ lan == 1 ? "图片" : "Pic" }}</div>
          <div>{{ lan == 1 ? "名称" : "Name" }}</div>
          <div>{{ lan == 1 ? "零件号" : "Part number" }}</div>
          <div>{{ lan == 1 ? "数量" : "Number" }}</div>
          <div>{{ lan == 1 ? "联系方式" : "TEL" }}</div>
          <div>{{ lan == 1 ? "操作" : "Edit" }}</div>
        </div>
        <div
          class="right_body_l"
          v-for="(item, index) in fir_info"
          :key="index"
        >
          <div>
            <img
              src="../assets/img/icon_09.png"
              alt
              v-if="!item.ischeck"
              @click="item.ischeck = !item.ischeck"
            />
            <img
              src="../assets/img/icon_08.png"
              v-if="item.ischeck"
              alt
              @click="item.ischeck = !item.ischeck"
            />
          </div>
          <div>
            <img :src="item.goods_logo" alt />
          </div>
          <div>{{ item.goods_name }}</div>
          <div>{{ item.specification_value }}</div>
          <div>
            <span>{{ item.num }}</span>
          </div>
          <div style="word-break: break-all">{{ item.mobile }}</div>
          <div>
            <img
              src="../assets/img/icon_10.png"
              alt
              @click="delthisone(item.cart_id, 1)"
            />
          </div>
        </div>
      </div>
      <div
        class="scbtn"
        @click="productsend"
        v-if="active == 1 && fir_info.length > 0"
      >
        {{ lan == 1 ? "发送询价单" : "sent" }}
      </div>
      <div class="right_box_z" v-if="active == 2">
        <div class="right_top_z">
          <div>{{ lan == 1 ? "图片" : "Pic" }}</div>
          <div>{{ lan == 1 ? "名称" : "Name" }}</div>
          <div>{{ lan == 1 ? "零件号" : "Part number" }}</div>
          <div>{{ lan == 1 ? "数量" : "Number" }}</div>
          <div>{{ lan == 1 ? "联系方式" : "TEL" }}</div>
        </div>
        <div
          class="right_body_z"
          v-for="(item, index) in fir_info"
          :key="index"
        >
          <div>
            <img :src="item.goods_logo" alt />
          </div>
          <div>{{ item.goods_name }}</div>
          <div>{{ item.specification_value }}</div>
          <div>
            <span>{{ item.num }}</span>
          </div>
          <div style="word-break: break-all">{{ item.mobile }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let users_id = localStorage.getItem("users_id");
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      error: false,
      sucsend: false,
      failsend: false,
      failmsg: "",
      sucmsg: "",
      active: 0,
      shadowshow: false,
      fir_info: [],
      name: "",
      number: "",
      wechect: "",
    };
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },

  methods: {
    //该购物车数量
    async sendnum(id, num) {
      id = id.toString();
      num = num.toString();
      console.log(id, num);
      let res = await axios.post(Vue.prototype.baseURL + `Index/modCartNum`, {
        cart_id: id,
        num: num,
      });
      location.reload();
    },
    async delthisone(id, i) {
      localStorage.setItem("i", i);
      let res = await axios.post(Vue.prototype.baseURL + `Index/deleteCart`, {
        cart_id: id,
      });
      console.log(res);
      if (res.data.code == 1) {
        this.sucsend = true;
        this.sucmsg = res.data.message;
        let that = this;
        setTimeout(() => {
          that.sucsend = false;
          location.reload();
        }, 1500);
      } else {
        this.failsend = true;
        this.failmsg = res.data.message;
        this.fir_info.splice(i, 1);
        let that = this;
        setTimeout(() => {
          that.failsend = false;
        }, 1500);
      }
    },
    //发送询价单
    async productsend() {
      let he = [];
      for (let i = 0; i < this.fir_info.length; i++) {
        for (let v in this.fir_info[i]) {
          if (v == "ischeck") {
            if (this.fir_info[i][v] == true) {
              he.push({ cart_id: this.fir_info[i].cart_id });
            }
          }
        }
      }

      let hi = JSON.stringify(he);
      let res = await axios.post(Vue.prototype.baseURL + `Index/sendSheet`, {
        cart_id_json: hi,
      });
      console.log(res);
      this.sucsend = true;
      this.sucmsg = res.data.message;
      let that = this;
      setTimeout(() => {
        this.sucsend = false;
      }, 2000);
      this.check(2);
    },
    //选择左侧tab
    check(v) {
      this.active = v;
      localStorage.setItem("i", v);
    },
    //显示隐藏
    changeshow() {
      this.shadowshow = true;
    },
    showfalse() {
      this.shadowshow = false;
    },
    // 发送文件
    async sendfile() {
      let o = [];
      console.log(this.name, this.number, this.wechect);
      // this.shadowshow = false;
      for (let i = 0; i < this.fir_info.length; i++) {
        for (let v in this.fir_info[i]) {
          if (v == "ischeck") {
            if (this.fir_info[i][v] == true) {
              o.push({ cart_id: this.fir_info[i].cart_id });
            }
          }
        }
      }
      let cart_id_json = JSON.stringify(o);
      if (this.name == "" || this.number == "" || this.wechect == "") {
        this.error = true;
        let that = this;
        setTimeout(() => {
          that.error = false;
        }, 2000);
        return false;
      }
      let res = await axios.post(Vue.prototype.baseURL + `Index/createSheet`, {
        cart_id_json,
        name: this.name,
        mobile: this.number,
        wx: this.wechect,
      });
      console.log(res);
      if (res.data.code == 1) {
        this.shadowshow = false;
        this.sucsend = true;
        if (this.lan == 1) {
          this.sucmsg = "询价单生成成功";
        }
        if (this.lan == 2) {
          this.sucmsg = "Inquiry sheet generated successfully";
        }
        let that = this;
        setTimeout(() => {
          that.sucsend = false;
        }, 2000);
        this.check(1);
      }
    },
  },
  async created() {
    if (users_id == null || users_id == "") {
      this.$router.push("/re");
    }
    let res = await axios.post(Vue.prototype.baseURL + `Index/cartList`, {
      users_id: users_id,
      status: this.active,
      lang_type: this.lan,
    });
    console.log(res);
    for (let i = 0; i < res.data.data.length; i++) {
      res.data.data[i].ischeck = true;
    }
    this.fir_info = res.data.data;
    let act = localStorage.getItem("i");
    if (act == null) {
      act = 0;
    }
    // this.check(act);
  },
  watch: {
    async active() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/cartList`, {
        users_id: users_id,
        status: this.active,
        lang_type: this.lan,
      });
      for (let i = 0; i < res.data.data.length; i++) {
        res.data.data[i].ischeck = true;
      }
      this.fir_info = res.data.data;
    },
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/cartList`, {
        users_id: users_id,
        status: this.active,
        lang_type: this.lan,
      });
      for (let i = 0; i < res.data.data.length; i++) {
        res.data.data[i].ischeck = true;
      }
      this.fir_info = res.data.data;
    },
  },
};
</script>

<style lang='scss' scoped>
.error {
  text-align: center;
  font-size: 1vw;
  color: #e02029;
}
@media screen and (max-width: 800px) {
  .cartcenter > ul > li:nth-of-type(2) {
    margin-top: 0vw !important;
  }
  .cartcenter {
    padding-top: 4vw;
    min-height: 58vh !important;
  }
  .cart_cent {
    width: 60vw !important;
    min-height: 54vw !important;
    background: #fff;
    margin: 50vw auto 0 auto;
    border-radius: 5px;
  }
  .title {
    line-height: 10vw !important;
    font-size: 5vw !important;
    text-align: center;
    border-bottom: 1px solid #dddddd;
    color: #008167;
    margin-bottom: 1.5vw;
  }
  .cart_cent > input {
    width: 80%;
    margin: auto;
    height: 7vw !important;
    border-radius: 1.5vw;
    border: 1px solid #dddddd;
    padding-left: 1vw;
    font-size: 3vw !important;
    margin-bottom: 1.5vw;
  }
  .cart_cent > input::placeholder {
    font-size: 3vw !important;
  }
  .enter_btn {
    cursor: pointer;
    width: 80%;
    margin: auto;
    height: 8vw !important;
    border-radius: 1.5vw;
    border: 1px solid #dddddd;
    font-size: 5vw !important;
    line-height: 7vw !important;
    text-align: center;
    margin-top: 3vw;
    color: #fff;
    background: #008167;
  }
  .right_box_z {
    min-height: 1vw;
    margin-left: 0 !important;
    margin-top: 7vw;
    border: 1px solid #dddddd;
  }
  .right_top_z > div {
    line-height: 6vw !important;
    font-size: 3vw !important;
    background: #f0f0f0;
    color: #666666;
  }
  .right_body_z > div {
    line-height: 3.8vw;
    font-size: 3vw;
    color: #666666;
  }
  .right_body_z > div:nth-of-type(1) > img {
    cursor: pointer;
    display: block;
    margin: auto;
    width: 7vw !important;
    height: 6vw !important;
  }
  .cartcenter > ul {
    float: none !important;
  }
  .right_body_l > div:nth-of-type(7) > img {
    height: 4vw !important;
    width: 3vw !important;
    cursor: pointer;
    margin: auto;
    margin-top: 1.2vw !important;
  }
  .cartcenter > ul > li {
    display: list-item !important ;
    font-size: 3vw !important;
    width: 15vw !important;
  }
  .hoverli {
    float: left;
  }
  .right_box {
    min-height: 1vw;
    margin-left: 0 !important;
    margin-top: 6vw;
    border: 1px solid #dddddd;
  }
  .right_top > div {
    line-height: 6vw !important;
    font-size: 3vw !important;
    background: #f0f0f0;
    color: #666666;
  }
  .right_body > div {
    line-height: 6vw !important;
    font-size: 3vw !important;
    color: #666666;
  }
  .right_body > div:nth-of-type(1) > img {
    cursor: pointer;
    display: block;
    line-height: 3.1vw;
    margin: auto;
    width: 3vw !important;
    height: 3vw !important;
    margin-top: 2.2vw !important;
  }
  .scbtn {
    cursor: pointer;
    margin: auto;
    width: 20vw !important;
    line-height: 9vw !important;
    text-align: center;
    font-size: 3vw !important;
    background: #008067;
    border-radius: 4.5vw !important;
    margin-top: 3vw;
    color: #fff;
    margin-bottom: 3vw !important;
  }
  .right_box_l {
    min-height: 1vw;
    margin-left: 0vw !important;
    border: 1px solid #dddddd;
    margin-top: 5vw;
  }
  .right_top_l > div {
    line-height: 5.5vw !important;
    font-size: 3vw !important;
    background: #f0f0f0;
    color: #666666;
  }
  .right_body_l > div:nth-of-type(1) > img {
    cursor: pointer;
    display: block;
    line-height: 3.1vw;
    margin: auto;
    width: 3vw !important;
    height: 3vw !important;
    margin-top: 1.35vw;
  }
  .right_body_l > div:nth-of-type(2) > img {
    display: block;
    margin: auto;
    width: 8vw !important;
    height: 6vw !important;
    margin-top: 1vw !important;
  }
  .right_body_l > div {
    line-height: 5.5vw !important;
    font-size: 3vw !important;
    color: #666666;
  }
  .right_body > div:nth-of-type(2) > img {
    display: block;
    margin: auto;
    width: 8vw !important;
    height: 6vw !important;
    margin-top: 1vw !important;
  }
  .right_body > div:nth-of-type(5) > input {
    display: block;
    width: 4vw !important;
    height: 5vw !important;
    text-align: center;
    margin-top: 1vw !important;
    margin-left: 1vw !important;
    margin-right: 0 !important;
  }
  .right_body > div:nth-of-type(6) > img {
    height: 4vw !important;
    width: 3vw !important;
    cursor: pointer;
    margin-top: 1.4vw !important;
    margin-left: 1vw !important;
  }
  .right_top > div:nth-of-type(5) {
    // text-align: center;
    line-height: 2.6vw;
    font-size: 1.1vw;
    background: #f0f0f0;
    color: #666666;
  }
  .cartcenter > ul > li:nth-of-type(1) {
    margin-top: -1vw;
    color: #008067;
  }
  .cartcenter > ul > li:nth-of-type(n + 1) {
    margin-top: 2vw !important;
  }
}
.right_body_z {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dddddd;
}
.right_body_z:nth-last-of-type(1) {
  border-bottom: none !important;
}
.right_body_z > div {
  line-height: 3.8vw;
  font-size: 1.1vw;
  color: #666666;
}
.right_body_z > div:nth-of-type(1) {
  flex: 1;
}
.right_body_z > div:nth-of-type(1) > img {
  cursor: pointer;
  display: block;
  margin: auto;
  width: 4.4vw;
  height: 3.7vw;
  margin-top: 5px;
}
.right_body_z > div:nth-of-type(2) {
  flex: 3;
  display: flex;
  line-height: normal;
  align-items: center;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.right_body_z > div:nth-of-type(2) > img {
  display: block;
  margin: auto;
  width: 4.4vw;
  height: 3.7vw;
  font-weight: 600;
}
.right_body_z > div:nth-of-type(3) {
  padding-left: 1vw;
  flex: 3;
  // font-weight: 600;
}
.right_body_z > div:nth-of-type(4) {
  flex: 1;
}
.right_body_z > div:nth-of-type(4) > span {
  display: block;
  width: 5.3vw;
  height: 1.47vw;
  margin: auto;
}
.right_body_z > div:nth-of-type(5) {
  flex: 1;
}
.right_body_z > div:nth-of-type(6) {
  flex: 1;
}
.right_body_z > div:nth-of-type(5) > input {
  width: 2.3vw;
  height: 1.47vw;
  margin: auto;
  text-align: center;
  margin-top: 0.8vw;
}
.right_body_z > div:nth-of-type(7) {
  flex: 1;
}
.right_body_z > div:nth-of-type(7) > img {
  height: 2vw;
  width: 1.8vw;
  cursor: pointer;
  margin: auto;
  margin-top: 0.9vw;
}

.right_body_z {
  width: 100%;
  display: flex;
}
.right_body_z:nth-last-of-type(1) {
  border-bottom: none;
}
.right_top_z > div {
  line-height: 2.6vw;
  font-size: 1.1vw;
  background: #f0f0f0;
  color: #666666;
}
.right_top_z > div:nth-of-type(1) {
  flex: 1;
  text-align: center;
}
.right_top_z > div:nth-of-type(2) {
  flex: 3;
}
.right_top_z > div:nth-of-type(3) {
  padding-left: 1vw;
  flex: 3;
}
.right_top_z > div:nth-of-type(4) {
  flex: 1;
}
.right_top_z > div:nth-of-type(5) {
  flex: 1;
}
.right_top_z > div:nth-of-type(6) {
  flex: 1;
}
.right_top_z > div:nth-of-type(7) {
  text-align: center;
  flex: 1;
}
.right_top_z {
  display: flex;
}
.right_box_z {
  min-height: 1vw;
  margin-left: 7vw;
  border: 1px solid #dddddd;
}
.right_body_l {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dddddd;
}
.right_body_l:nth-last-of-type(1) {
  border-bottom: none !important;
}
.right_body_l > div {
  line-height: 3.8vw;
  font-size: 1.1vw;
  color: #666666;
}
.right_body_l > div:nth-of-type(1) {
  flex: 1;
}
.right_body_l > div:nth-of-type(1) > img {
  cursor: pointer;
  display: block;
  line-height: 3.1vw;
  margin: auto;
  width: 1.2vw;
  height: 1.2vw;
  margin-top: 1.35vw;
}
.right_body_l > div:nth-of-type(2) {
  text-align: center;
  flex: 1;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.right_body_l > div:nth-of-type(2) > img {
  display: block;
  margin: 5px auto 0 auto;
  width: 4.4vw;
  height: 3.7vw;
}
.right_body_l > div:nth-of-type(3) {
  line-height: normal;
  display: flex;
  align-items: center;
  padding-left: 1vw;
  flex: 3;
  // font-weight: 600;
}
.right_body_l > div:nth-of-type(4) {
  flex: 2.5;
}
.right_body_l > div:nth-of-type(5) {
  flex: 1.5;
}
.right_body_l > div:nth-of-type(5) > span {
  display: block;
  width: 4.5vw;
  height: 1.47vw;
  margin: auto;
  text-align: center;
}
.right_body_l > div:nth-of-type(6) {
  flex: 1;
}
.right_body_l > div:nth-of-type(5) > input {
  width: 2.3vw;
  height: 1.47vw;
  margin: auto;
  text-align: center;
  margin-top: 0.8vw;
}
.right_body_l > div:nth-of-type(7) {
  flex: 1;
}
.right_body_l > div:nth-of-type(7) > img {
  height: 1.3vw;
  width: 1.1vw;
  cursor: pointer;
  margin: auto;
  margin-top: 0.9vw;
}

.right_body_l {
  width: 100%;
  display: flex;
}
.right_body_l:nth-last-of-type(1) {
  border-bottom: none;
}
.right_top_l > div {
  line-height: 2.6vw;
  font-size: 1.1vw;
  background: #f0f0f0;
  color: #666666;
}
.right_top_l > div:nth-of-type(1) {
  flex: 1;
  text-align: center;
}
.right_top_l > div:nth-of-type(2) {
  flex: 1;
  text-align: center;
}
.right_top_l > div:nth-of-type(3) {
  padding-left: 1vw;
  flex: 3;
}
.right_top_l > div:nth-of-type(4) {
  flex: 2.5;
}
.right_top_l > div:nth-of-type(5) {
  flex: 1.5;
  text-align: center;
}
.right_top_l > div:nth-of-type(6) {
  flex: 1;
}
.right_top_l > div:nth-of-type(7) {
  text-align: center;
  flex: 1;
}
.right_top_l {
  display: flex;
}
.right_box_l {
  min-height: 1vw;
  margin-left: 7vw;
  border: 1px solid #dddddd;
}
.title {
  line-height: 3vw;
  font-size: 1vw;
  text-align: center;
  border-bottom: 1px solid #dddddd;
  color: #008167;
  margin-bottom: 1.5vw;
}
.cart_cent {
  width: 25vw;
  height: 30vw;
  background: #fff;
  margin: 10vw auto 0 auto;
  border-radius: 5px;
}
.enter_btn {
  cursor: pointer;
  width: 80%;
  margin: auto;
  height: 3vw;
  border-radius: 1.5vw;
  border: 1px solid #dddddd;
  font-size: 1vw;
  line-height: 3vw;
  text-align: center;
  margin-top: 3vw;
  color: #fff;
  background: #008167;
}
.cart_cent > input {
  display: block;
}
.cart_cent > input {
  width: 80%;
  margin: auto;
  height: 3vw;
  border-radius: 1.5vw;
  border: 1px solid #dddddd;
  padding-left: 1vw;
  font-size: 1vw;
  margin-bottom: 1.5vw;
}
.cart_cent > input::placeholder {
  font-size: 1vw;
}
.cart_xjd {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.5);
}
.scbtn {
  cursor: pointer;
  margin: auto;
  width: 10vw;
  line-height: 3vw;
  text-align: center;
  font-size: 1.2vw;
  background: #008067;
  border-radius: 1.5vw;
  margin-top: 3vw;
  color: #fff;
  margin-bottom: 3vw;
}

.right_body {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dddddd;
}
.right_body:nth-last-of-type(1) {
  border-bottom: none !important;
}
.right_body > div {
  // border-bottom: 1px solid #dddddd;
  line-height: 3.8vw;
  font-size: 1.1vw;
  color: #666666;
  padding-right: 5px;
}
.right_body > div:nth-of-type(1) {
  flex: 1;
}
.right_body > div:nth-of-type(1) > img {
  cursor: pointer;
  display: block;
  line-height: 3.1vw;
  margin: auto;
  width: 1.2vw;
  height: 1.2vw;
  margin-top: 1.35vw;
}
.right_body > div:nth-of-type(2) {
  text-align: center;
  flex: 1;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.right_body > div:nth-of-type(2) > img {
  display: block;
  margin: 5px auto;
  width: 4.4vw;
  height: 3.7vw;
}
.right_body > div:nth-of-type(3) {
  padding-left: 1vw;
  line-height: normal;
  display: flex;
  flex: 3;
  align-items: center;
  // justify-content: center;
  // font-weight: 600;
}
.right_body > div:nth-of-type(4) {
  flex: 3;
}
.right_body > div:nth-of-type(5) {
  flex: 1;
}
.right_body > div:nth-of-type(5) > input {
  width: 2.3vw;
  height: 1.47vw;
  margin: auto;
  text-align: center;
  margin-top: 0.8vw;
}
.right_body > div:nth-of-type(6) {
  flex: 1;
}
.right_body > div:nth-of-type(6) > img {
  height: 1.3vw;
  width: 1.1vw;
  cursor: pointer;
  margin-left: 0.7vw;
  margin-top: 1vw;
}
.right_top {
  width: 100%;
  display: flex;
}
.right_top > div {
  line-height: 2.6vw;
  font-size: 1.1vw;
  background: #f0f0f0;
  color: #666666;
}
.right_top > div:nth-of-type(1) {
  flex: 1;
  text-align: center;
}
.right_top > div:nth-of-type(2) {
  flex: 1;
  text-align: center;
}
.right_top > div:nth-of-type(3) {
  padding-left: 1vw;
  flex: 3;
}
.right_top > div:nth-of-type(4) {
  flex: 3;
}
.right_top > div:nth-of-type(5) {
  flex: 1;
}
.right_top > div:nth-of-type(6) {
  flex: 1;
}
.right_box {
  min-height: 1vw;
  margin-left: 7vw;
  border: 1px solid #dddddd;
}
.active_li {
  color: #008067;
}

.hoverli {
  cursor: pointer;
  width: 4vw;
  line-height: 2.5vw;
}
.cart_box {
}
.cartcenter {
  padding-top: 4vw;
  min-height: 37vw;
}
.cartcenter > ul {
  float: left;
}
.cartcenter > ul > li {
  display: block;
  font-size: 1vw;
  width: 6vw;
}
.cartcenter > ul > li:nth-of-type(1) {
  color: #008067;
}
.cartcenter > ul > li:nth-of-type(2) {
  margin-top: 0.8vw;
}
</style>