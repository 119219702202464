<template>
  <div class="us_box">
    <div class="title">{{ lan == 1 ? "联系我们" : "Contact us" }}</div>
    <div class="telinfo">
      <div class="email" @mouseover="change(1)" @mouseleave="change(0)">
        <img
          src="../assets/img/icon_11.png"
          alt
          v-if="hover == 0 || hover == 2 || hover == 3"
        />
        <img src="../assets/img/emailhover.png" alt v-if="hover == 1" />
        <div>Email</div>
        <div>
          <a href="mailto:info@sampling.com">{{ detail_info.email }}</a>
        </div>
      </div>
      <div class="email" @mouseover="change(2)" @mouseleave="change(0)">
        <img
          src="../assets/img/icon_12.png"
          class="myphone"
          alt
          v-if="hover == 0 || hover == 1 || hover == 3"
        />
        <img
          src="../assets/img/phonehover.png"
          alt
          v-if="hover == 2"
          class="myphone"
        />
        <div>{{ lan == 1 ? "联系电话" : "Phone No" }}</div>
        <div>{{ detail_info.contact_mobile }}</div>
      </div>
      <div class="email" @mouseover="change(3)" @mouseleave="change(0)">
        <img
          src="../assets/img/icon_13.png"
          alt
          v-if="hover == 0 || hover == 1 || hover == 2"
        />
        <img src="../assets/img/wxhover.png" alt v-if="hover == 3" />
        <div>{{ lan == 1 ? "微信" : "We-chat" }}</div>
        <div>{{ detail_info.wechat_num }}</div>
      </div>
      <div class="qrcode">
        <img :src="detail_info.wechat_qrcode" alt />
        <div>{{ lan == 1 ? "微信二维码" : "Wechat QR code" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// var lan = localStorage.getItem("lan");
// window.addEventListener("storage", function (e) {
//   lan = e.newValue;
//   console.log(e.newValue);
// });
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
export default {
  props: ["active_lan"],
  data() {
    return {
      detail_info: {},
      hover: 0,
      // lan: lan,
      // lan: this.$store.state.lan,
    };
  },
  methods: {
    change(i) {
      this.hover = i;
    },
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
  async created() {
    let res = await axios.post(Vue.prototype.baseURL + `Index/contactUs`);
    // console.log(res);
    this.detail_info = res.data.data;
  },
  watch: {
    lan() {},
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 800px) {
  .email > div:nth-of-type(2) {
    font-size: 2.5vw !important;
    text-align: center;
    font-weight: 600;
    line-height: 4vw !important;
  }
  .qrcode > div {
    width: 100%;
    margin-top: 0.5vw;
    text-align: center;
    line-height: 8vw;
    font-size: 2.5vw !important;
    color: #dddddd;
  }
  .qrcode {
    // padding-top: 9vw;
  }
  .qrcode > img {
    width: 10vw !important;
    height: 10vw !important;
    display: block !important;
    margin: 0 auto 0 auto !important;
    margin-top: 9vw !important;
  }
  .us_box {
    width: 90vw !important;
    height: 58vw !important;
    // padding: 5.2vw 0 52vh 0 !important;
    margin: auto;
    // min-height: 58vh;
    margin-bottom: 26vh !important;
  }
  .title {
    font-size: 3.5vw !important;
    line-height: 6vw !important;
    margin-bottom: 5vw;
    color: #0e886f;
  }
  .email {
    display: flex;
    flex-wrap: wrap;
    padding-top: 9vw !important;
    flex-direction: column;
  }

  .email > img {
    display: block;
    margin: 0 auto;
    width: 8vw !important;
    height: 6vw !important;
  }
  .myphone[data-v-02721738] {
    width: 6vw !important;
  }
  .email > div:nth-of-type(1) {
    font-size: 3.5vw !important;
    text-align: center;
    font-weight: 600;
    line-height: 9vw !important;
  }
}
.qrcode {
  // background: #0e886f;
  // padding-top: 9vw !important;
}
.qrcode > img {
  width: 6.5vw;
  height: 6.5vw;
  display: block;
  margin: 0 auto;
  margin-top: 3vw;
}
.email:hover {
  background: #0e886f !important;
  color: #dddddd !important;
}

.qrcode > div {
  width: 100%;
  margin-top: 0.5vw;
  text-align: center;
  font-size: 1.2vw;
  color: #999;
}
.qrcode:hover {
  background: #0e886f;
}
.qrcode:hover > div {
  color: #ddd;
}
.us_box {
  width: 42.1vw;
  height: 32.7vw;
  padding: 5.2vw 0;
  margin: auto;
}
.title {
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 600;
  margin-bottom: 5vw;
  color: #0e886f;
}
.telinfo {
  width: 100%;
  height: 80%;
  border: 1px solid #dddddd;
  display: flex;
}
.telinfo > div {
  flex: 1;
}
.email {
  width: 3vw;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4vw;
  flex-direction: column;
  color: #999999;
}
.email > img {
  display: block;
  margin: 0 auto;
  width: 4vw;
  height: 3vw;
}
.myphone {
  width: 3vw !important;
}
.email > div:nth-of-type(1) {
  font-size: 1.2vw;
  text-align: center;
  font-weight: 600;
  line-height: 3.1vw;
}

.email > div:nth-of-type(2) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8vw;
  text-align: center;
  font-weight: 600;
  // color: #999999;
  line-height: 2vw;
}
.email > div:nth-of-type(2) > a {
  width: 100%;
  text-decoration: none;
  color: #999999;
}
</style>