<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 mytext">
          <img src="../assets/img/icon_06.png" class="success_img" alt />
          <div>{{lan==1?'注册成功':'Login was successful'}}</div>
          <div>{{lan==1?"感谢您注册成为sampling官网用户":'Thank you for registering as a sampling official website user'}}</div>
          <div @click="tologin">{{lan==1?'去登录':'Go and land'}}</div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    tologin() {
      this.$router.push("/re");
    },
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 800px) {
  .success_img {
    width: 14vw !important;
    height: 14vw !important;
    margin: 7.3vw auto 0 auto;
  }
  .mytext > div[data-v-4205954e]:nth-of-type(1) {
    line-height: 7vw !important;
    font-size: 4vw !important;
    color: #008067;
  }
  .mytext > div[data-v-4205954e]:nth-of-type(2) {
    font-size: 4vw !important;
    color: #9a9a9a;
  }
  .mytext > div[data-v-4205954e]:nth-of-type(3) {
    width: 40vw !important;
    height: 7vw !important ;
    line-height: 7vw !important;
    margin: auto;
    background: #008067;
    margin-top: 2vw;
    margin-bottom: 52vw !important;
    border-radius: 1.25vw;
    font-size: 4vw;
    color: #feffff;
  }
}
.mytext {
  text-align: center;
}
.mytext > div:nth-of-type(1) {
  line-height: 4vw;
  font-size: 1.3vw;
  color: #008067;
}
.mytext > div:nth-of-type(2) {
  font-size: 1vw;
  color: #9a9a9a;
}
.mytext > div:nth-of-type(3) {
  width: 10vw;
  height: 2.5vw;
  line-height: 2.5vw;
  margin: auto;
  background: #008067;
  margin-top: 2vw;
  margin-bottom: 7.5vw;
  border-radius: 1.25vw;
  font-size: 1vw;
  color: #feffff;
}
.success_img {
  width: 6.3vw;
  height: 6.3vw;
  margin: 7.3vw auto 0 auto;
}
</style>