<template>
  <div class="productbox">
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="200"
    >
      <div class="infobox common" v-if="fir_list.length != 0">
        <div v-for="(item, index) in fir_list" :key="index">
          <img :src="item.goods_logo" alt @click="gotodetail(item.goods_id)" />
          <div class="infonext_box">
            <div>{{ item.goods_name }}</div>
            <div @click="takejjinfo(item.goods_intro)">
              {{ item.goods_intro }}
            </div>
            <div @click="gotodetail(item.goods_id)">
              {{ lan == 1 ? "查看详情" : "View details" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zzc" v-if="jjinfoshow" @click="jjinfoshow = false">
      <div class="zzcinfo">
        {{ jjinfo }}
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
import axios from "axios";
import infiniteScroll from "vue-infinite-scroll";
// let p = 1;
// let flag = true;
// $(window).scroll(function () {
//   //页面总高度
//   var scrollHeight = $(document).height();
//   //已经滚动到上面的页面高度
//   var scrollTop = $(this).scrollTop() + 280;
//   //浏览器窗口高度
//   var windowHeight = $(this).height();
//   //此处是滚动条到底部时候触发的事件，在这里写要加载的数据，或者是拉动滚动条的操作
//   console.log(scrollTop);
//   if (flag) {
//     if (scrollTop + windowHeight >= scrollHeight) {
//       //这里就是拉到了最底下,发请求获取下一页
//       // console.log(11111);
//       // console.log(scrollTop + windowHeight + 100 == scrollHeight);
//       p++;
//       console.log(p);
//       flag = false;
//     }
//   }
// });
export default {
  // props: ["dedao"],
  computed: {
    lan() {
      return this.$store.state.lan;
    },
    fir_id() {
      return this.$store.state.fir_id;
    },
    sec_id() {
      return this.$store.state.sec_id;
    },
    thr_id() {
      return this.$store.state.thr_id;
    },
    for_id() {
      return this.$store.state.for_id;
    },
    all_id() {
      return this.$store.state.all_id;
    },
  },
  data() {
    return {
      tab: "",
      title: 0,
      fir_list: [],
      p: 0,
      count: 50,
      busy: false,
      jjinfoshow: false,
      jjinfo: "",
    };
  },
  directives: {
    infiniteScroll,
  },
  methods: {
    loadMore() {
      this.busy = true;
      this.count = this.count + 50;
      this.busy = false;
      this.p++;
      console.log(this.p);
    },
    gotodetail(id) {
      this.$router.push(`/detail/${id}`);
    },
    takejjinfo(info) {
      this.jjinfoshow = true;
      this.jjinfo = info;
    },
  },
  async created() {
    let res = await axios.post(Vue.prototype.baseURL + `Index/goodsList`, {
      lang_type: this.lan,
      goods_type_id: 0,
      p: 1,
    });
    console.log(res);
    this.fir_list = res.data.data;
  },
  watch: {
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/goodsList`, {
        lang_type: this.lan,
        goods_type_id: 0,
        p: 0,
      });
      console.log(this.p);
      // this.p = 0;
      console.log(res);
      this.fir_list = res.data.data;
    },
    async fir_id() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/goodsList`, {
        lang_type: this.lan,
        goods_type_id: this.fir_id,
        p: 1,
      });
      this.p = 1;

      console.log(res);
      this.fir_list = res.data.data;
    },
    async sec_id() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/goodsList`, {
        lang_type: this.lan,
        goods_type_id: this.sec_id,
        p: 1,
      });
      this.p = 1;

      console.log(res);
      this.fir_list = res.data.data;
    },
    async thr_id() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/goodsList`, {
        lang_type: this.lan,
        goods_type_id: this.thr_id,
        p: 1,
      });
      console.log(this.p);
      this.p = 1;
      console.log(res);
      this.fir_list = res.data.data;
    },
    async p() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/goodsList`, {
        lang_type: this.lan,
        goods_type_id: this.all_id,
        p: this.p,
      });
      // console.log(this.p);
      console.log(res);
      let qus = res.data.data;
      if (res.data.data.length == 0) {
      }
      this.fir_list = this.fir_list.concat(qus);
    },
  },
};
</script>

<style lang="scss" scoped>
.zzcinfo {
  width: 500px;
  height: 600px;
  margin: 100px auto;
  background: #ffffff;
  font-size: 1vw;
  padding: 60px;
}
.zzc {
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 800px) {
  .zzcinfo {
    width: 300px;
    height: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #ffffff;
    font-size: 3.5vw;
    padding: 60px;
  }
  .infobox > div {
    margin-bottom: 3vw;
    margin-right: 2vw !important;
    width: 45vw !important;
    // height: 390px;
    height: 50vw !important;
    background: #fff;
    border: 1px solid #ededed;
  }
  .infonext_box > div:nth-of-type(1) {
    line-height: 4.5vw !important;
    font-size: 3vw !important;
    height: 9vw;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .infonext_box > div:nth-of-type(2) {
    line-height: 3.5vw !important;
    font-size: 3vw !important;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
  }
  .infonext_box > div:nth-of-type(3) {
    font-size: 3vw !important;
    line-height: 4.5vw !important;
    width: 16vw !important;
    background: #008067;
    text-align: center;
    border-radius: 0.75vw;
    color: #fff;
    position: absolute;
    left: 1vw;
    bottom: 1vw;
  }
}
.infonext_box > div:nth-of-type(3) {
  cursor: pointer;
  font-size: 1vw;
  line-height: 1.5vw;
  width: 6vw;
  background: #008067;
  text-align: center;
  border-radius: 0.75vw;
  color: #fff;
  position: absolute;
  left: 1vw;
  bottom: 1vw;
}
.infonext_box > div:nth-of-type(1) {
  line-height: 1.8vw;
  font-size: 1vw;
}
.infonext_box > div:nth-of-type(2) {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  height: 3.6vw;
  line-height: 1.2vw;
  font-size: 1vw;
  color: #999;
  -webkit-box-orient: vertical;
}
.infonext_box {
  padding: 1vw;
  height: 45%;
  position: relative;
}
.infobox {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  justify-self: start;
  background: #f8f8f8;
  padding: 1.9vw 2.8vw;
}
.infobox > div > img {
  width: 100%;
  height: 55%;
  border-bottom: 1px solid #ededed;
}
.infobox > div {
  margin-right: 3.5vw;
  margin-bottom: 3vw;
  width: 310px;
  height: 432px;
  background: #fff;
  border: 1px solid #ededed;
  cursor: pointer;
}
.active_t {
  color: #008067;
}
.active_t > div {
  height: 2px;
  width: 1.05vw;
  margin: auto;
  background: #008067;
}
.productbox {
  min-height: 59.9vh;
}
// .topbox > .xrtop {
//   width: 100%;
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
// }
// .topbox > .tctop {
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
//   background: #d7e6e3;
//   color: #666;
// }
.act {
  color: #008067 !important;
}
// .topbox {
//   line-height: 6.9vh;
//   display: flex;
//   width: 100%;
//   background: #f8f8f8;
// }
</style>