<template>
  <div class="serch_box">
    <div class="serch_title common">搜索结果</div>
    <div class="infobox common">
      <div v-for="(item, index) in detail_info" :key="index">
        <img :src="item.goods_logo" alt />
        <div class="infonext_box">
          <div>{{ item.goods_name }}</div>
          <div>{{ item.goods_intro }}</div>
          <div @click="gotodetail(item.goods_id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
let username = localStorage.getItem("username");
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      detail_info: [],
    };
  },
  methods: {
    gotodetail(id) {
      this.$router.push(`/detail/${id}`);
    },
  },
  async created() {
    let res = await axios.post(Vue.prototype.baseURL + `Index/search`, {
      lang_type: this.lan,
      keywords: this.$store.state.serch_val,
    });
    console.log(res);
    this.detail_info = res.data.data;
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
    serch_val() {
      return this.$store.state.serch_val;
    },
  },
  watch: {
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/search`, {
        lang_type: this.lan,
        keywords: this.$store.state.serch_val,
      });
      console.log(res);
      this.detail_info = res.data.data;
    },
    async serch_val() {
      // console.log(21111);
      let res = await axios.post(Vue.prototype.baseURL + `Index/search`, {
        lang_type: this.lan,
        keywords: this.$store.state.serch_val,
      });
      console.log(res);
      this.detail_info = res.data.data;
    },
  },
};
</script>

// <style lang="scss" scoped>
// .serch_title {
//   font-size: 1.5vw;
//   line-height: 3vw;
//   margin-top: 2vw !important;
// }

// @media screen and (max-width: 800px) {
//   .zzcinfo {
//     width: 300px;
//     height: 300px;
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     margin: auto;
//     background: #ffffff;
//     font-size: 3.5vw;
//     padding: 60px;
//   }
//   .infobox > div {
//     margin-bottom: 3vw;
//     margin-right: 2vw !important;
//     width: 45vw !important;
//     // height: 390px;
//     height: 50vw !important;
//     background: #fff;
//     border: 1px solid #ededed;
//   }
//   .infonext_box > div:nth-of-type(1) {
//     line-height: 4.5vw !important;
//     font-size: 3vw !important;
//   }
//   .infonext_box > div:nth-of-type(2) {
//     line-height: 3.5vw !important;
//     font-size: 3vw !important;
//   }
//   .infonext_box > div:nth-of-type(3) {
//     font-size: 3vw !important;
//     line-height: 4.5vw !important;
//     width: 16vw !important;
//     background: #008067;
//     text-align: center;
//     border-radius: 0.75vw;
//     color: #fff;
//     position: absolute;
//     left: 1vw;
//     bottom: 1vw;
//   }
// }
// .infonext_box > div:nth-of-type(3) {
//   font-size: 1vw;
//   line-height: 1.5vw;
//   width: 6vw;
//   background: #008067;
//   text-align: center;
//   border-radius: 0.75vw;
//   color: #fff;
//   position: absolute;
//   left: 1vw;
//   bottom: 1vw;
// }
// .infonext_box > div:nth-of-type(1) {
//   line-height: 1.8vw;
//   font-size: 1vw;
// }
// .infonext_box > div:nth-of-type(2) {
//   display: -webkit-box;
//   overflow: hidden;
//   white-space: normal !important;
//   text-overflow: ellipsis;
//   word-wrap: break-word;
//   -webkit-line-clamp: 4;
//   line-height: 1.2vw;
//   font-size: 1vw;
//   height: 4.8vw;
//   color: #999;
//   -webkit-box-orient: vertical;
// }
// .infonext_box {
//   padding: 1vw;
//   height: 45%;
//   position: relative;
// }
// .infobox {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: start;
//   //background: #f8f8f8;
//   padding: 1.9vw 0;
// }
// .infobox > div > img {
//   width: 100%;
//   height: 55%;
//   border-bottom: 1px solid #ededed;
// }
// .infobox > div {
//   margin-bottom: 3vw;
//   margin-right: 5.5vw;
//   width: 310px;
//   height: 432px;
//   background: #fff;
//   // margin-left: 5vw;
//   border: 1px solid #ededed;
// }
// .active_t {
//   color: #008067;
// }
// .active_t > div {
//   height: 2px;
//   width: 1.05vw;
//   margin: auto;
//   background: #008067;
// }
// .productbox {
//   min-height: 59.9vh;
// }
// .topbox > div {
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
// }
// .topbox {
//   line-height: 6.9vh;
//   display: flex;
//   width: 100%;
//   background: #f8f8f8;
// }
//
</style>
<style lang="scss" scoped>
.zzcinfo {
  width: 500px;
  height: 600px;
  margin: 100px auto;
  background: #ffffff;
  font-size: 1vw;
  padding: 60px;
}
.zzc {
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 800px) {
  .zzcinfo {
    width: 300px;
    height: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #ffffff;
    font-size: 3.5vw;
    padding: 60px;
  }
  .infobox > div {
    margin-bottom: 3vw;
    margin-right: 2vw !important;
    width: 45vw !important;
    // height: 390px;
    height: 50vw !important;
    background: #fff;
    border: 1px solid #ededed;
  }
  .infonext_box > div:nth-of-type(1) {
    line-height: 4.5vw !important;
    font-size: 3vw !important;
  }
  .infonext_box > div:nth-of-type(2) {
    line-height: 3.5vw !important;
    font-size: 3vw !important;
  }
  .infonext_box > div:nth-of-type(3) {
    font-size: 3vw !important;
    line-height: 4.5vw !important;
    width: 16vw !important;
    background: #008067;
    text-align: center;
    border-radius: 0.75vw;
    color: #fff;
    position: absolute;
    left: 1vw;
    bottom: 1vw;
  }
}
.infonext_box > div:nth-of-type(3) {
  cursor: pointer;
  font-size: 1vw;
  line-height: 1.5vw;
  width: 6vw;
  background: #008067;
  text-align: center;
  border-radius: 0.75vw;
  color: #fff;
  position: absolute;
  left: 1vw;
  bottom: 1vw;
}
.infonext_box > div:nth-of-type(1) {
  line-height: 1.8vw;
  font-size: 1vw;
}
.infonext_box > div:nth-of-type(2) {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  height: 3.6vw;
  line-height: 1.2vw;
  font-size: 1vw;
  color: #999;
  -webkit-box-orient: vertical;
}
.infonext_box {
  padding: 1vw;
  height: 45%;
  position: relative;
}
.infobox {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  justify-self: start;
  background: #f8f8f8;
  padding: 1.9vw 2.8vw;
}
.infobox > div > img {
  width: 100%;
  height: 55%;
  border-bottom: 1px solid #ededed;
}
.infobox > div {
  margin-right: 3.5vw;
  margin-bottom: 3vw;
  width: 310px;
  height: 432px;
  background: #fff;
  border: 1px solid #ededed;
  cursor: pointer;
}
.active_t {
  color: #008067;
}
.active_t > div {
  height: 2px;
  width: 1.05vw;
  margin: auto;
  background: #008067;
}
.productbox {
  min-height: 59.9vh;
}
// .topbox > .xrtop {
//   width: 100%;
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
// }
// .topbox > .tctop {
//   flex: 1;
//   text-align: center;
//   font-size: 1.2vw;
//   background: #d7e6e3;
//   color: #666;
// }
.act {
  color: #008067 !important;
}
// .topbox {
//   line-height: 6.9vh;
//   display: flex;
//   width: 100%;
//   background: #f8f8f8;
// }
</style>