<template>
  <div class="index_allBox">
    <!-- 提示框 -->
    <div class="common ts">
      <b-alert v-model="issend" variant="danger">{{falsedetail}}</b-alert>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 userwl">{{lan==1?'账号登录':'Account login'}}</div>
        <div class="col-2"></div>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 inpinfo_l">
          <div class="user_name">
            <div>{{lan==1?'用户名':'User Name'}}</div>
            <input type="text" placeholder="请输入您的用户名" v-model="username" v-if="lan==1" />
            <input type="text" placeholder="Enter your user name" v-model="username" v-if="lan==2" />
          </div>
          <div class="user_name">
            <div>{{lan==1?'用户密码':'User Password'}}</div>
            <input
              type="password"
              placeholder="请输入您的用户密码"
              v-if="fir_eye&&lan==1"
              v-model="fir_password"
            />
            <input
              type="password"
              placeholder="Enter your password"
              v-if="fir_eye&&lan==2"
              v-model="fir_password"
            />
            <input
              type="text"
              placeholder="请输入您的用户密码"
              v-if="!fir_eye&&lan==1"
              v-model="fir_password"
            />
            <input
              type="text"
              placeholder="Enter your password"
              v-if="!fir_eye&&lan==2"
              v-model="fir_password"
            />
            <img src="../assets/img/icon_04.png" alt v-if="fir_eye" @click="fir_eye=!fir_eye" />
            <img src="../assets/img/icon_05.png" alt v-if="!fir_eye" @click="fir_eye=!fir_eye" />
          </div>
          <div
            class="detail_l"
            v-if="toindex"
          >{{lan==1?'登录成功，即将跳转首页':'Login successfully, will jump to the home page'}}</div>
          <div class="btn_1" @click="login">{{lan==1?'登录':'Login'}}</div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      hehe: true,
      username: "",
      fir_eye: true,
      fir_password: "",
      issend: false,
      toindex: false,
      falsedetail: "",
    };
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
  methods: {
    async login() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/login`, {
        account: this.username,
        password: this.fir_password,
      });
      console.log(res);
      if (res.data.code == 0) {
        this.issend = true;
        this.falsedetail = res.data.message;
        let that = this;
        setTimeout(() => {
          that.issend = false;
        }, 2000);
      }
      if (res.data.code == 1) {
        localStorage.setItem("username", this.username);
        this.issend = false;
        this.toindex = true;
        localStorage.setItem("users_id", res.data.data.users_id);
        let hehe = setTimeout(() => {
          this.$router.push("/");
        }, 2000);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@media screen and (max-width: 800px) {
  .detail_l {
    text-align: center;
    font-size: 3.5vw !important;
    color: #008167;
    margin-top: 20px;
  }
  .enter_btn {
    cursor: pointer;
    width: 80%;
    margin: auto;
    height: 8vw !important;
    border-radius: 1.5vw;
    border: 1px solid #dddddd;
    font-size: 5vw !important;
    line-height: 7vw !important;
    text-align: center;
    margin-top: 3vw;
    color: #fff;
    background: #008167;
  }
  .cart_cent {
    width: 60vw !important;
    height: 50vw !important;
    background: #fff;
    margin: 50vw auto 0 auto;
    border-radius: 5px;
  }
  .cart_cent > .other {
    font-size: 20px !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .index_allBox {
    // height: 102vh;
    min-height: 58vh;
  }
  .userwl {
    font-size: 3.2vw !important;
  }
  .user_name {
    width: 40vw !important;
  }
  .user_name > div {
    line-height: 7.2vw !important;
    font-size: 3vw !important;
  }
  .user_name > input {
    height: 9.1vw !important;
    font-size: 3.5vw !important;
  }
  .user_name > input::placeholder {
    font-size: 3.5vw !important;
  }
  .user_name > img {
    width: 2.6vw !important;
    height: 1.6vw !important;
    bottom: 3.2vw !important;
  }
  .btn_1 {
    line-height: 6.6vw !important;
    font-size: 3.3vw !important;
  }
}
.cart_cent > .other {
  text-align: left;
  color: #ce0743;
  font-size: 20px;
}
.enter_btn {
  cursor: pointer;
  width: 20%;
  margin: auto;
  height: 3vw;
  border: 1px solid #dddddd;
  font-size: 1vw;
  line-height: 3vw;
  text-align: center;
  margin-top: 2vw;
  color: #fff;
  background: #008167;
}
.cart_cent {
  overflow: hidden;
  width: 25vw;
  height: 7.5vw;
  background: #fff;
  margin: 10vw auto 0 auto;
  border-radius: 5px;
}
.cart_xjd {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.detail_l {
  text-align: center;
  font-size: 1.5vw;
  color: #008167;
  margin-top: 20px;
}
.detail {
  text-align: center;
  font-size: 1.5vw;
  color: #ce0743;
  margin-top: 20px;
}
.btn_1 {
  display: block;
  width: 10.5vw;
  margin: 2.5vw auto;
  text-align: center;
  line-height: 2.6vw;
  font-size: 1vw;
  color: #f8f8f8;
  background: #008067;
  border-radius: 1.3vw;
}
.user_name {
  width: 19.8vw;
  margin: 0 auto;
  color: #656565;
  position: relative;
}
.user_name > img {
  position: absolute;
  bottom: 1.2vw;
  right: 1vw;
  width: 1.2vw;
  height: 0.6vw;
}
.user_name > div {
  font-size: 1.2vw;
  line-height: 3vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user_name > input {
  width: 100%;
  height: 3.1vw;
  // border: none;
  border-radius: 0.26vw;
  background: #f0f0f0;
  font-size: 1vw;
  border: 1px solid #f8f8f8;
  padding-left: 1vw;
}
.user_name > input:focus {
  border: 1px solid #008067;
}
.user_name > input::placeholder {
  font-size: 1vw;
}
.inpinfo_l {
  // height: 28vw;
  margin-bottom: 10vw;
  background: #fff;
  padding: 1.8vw 0;
}
.index_allBox {
  // height: 102vh;

  padding-top: 3.7vw;
  background: #f8f8f8;
}
.userwl {
  margin-bottom: 2vw;
  font-size: 1.2vw;
  color: #018168;
  font-weight: 600;
}
</style>