import '@babel/polyfill' //引入ie
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker.js'
import "./assets/css/common.css"
Vue.config.productionTip = false
import axios from "axios"
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue'
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.prototype.baseURL = "https://admin.samplingshop.cn/index.php/home/"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')