import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lan: 0,
    newsid: '',
    serch_val: '',
    fir_id: '',
    sec_id: '',
    thr_id: '',
    for_id: '',
    all_id: 0
  },
  mutations: {
    changelangui(state, val) {
      state.lan = val;
      console.log(val);
    },
    changenewsid(state, val) {
      state.newsid = val;
      console.log(val);
    },
    sent_serch(state, val) {
      state.serch_val = val;
      console.log(state.serch_val);
    },
    change_firid(state, val) {
      state.fir_id = val;
      console.log(val);

    },
    change_secid(state, val) {
      state.sec_id = val;
      console.log(val);

    },
    change_thrid(state, val) {
      console.log(val);
      state.thr_id = val;
    },
    change_forid(state, val) {
      console.log(val);
      state.for_id = val;
    },
    change_allid(state, val) {
      console.log(val);
      state.all_id = val;
    }
  },
  actions: {},
  modules: {}
})