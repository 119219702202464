<template>
  <div class="news_box">
    <div class="container bannerinfo">
      <div class="col-4 aboutus">{{render.title}}</div>
      <div>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          indicators
          background="#ababab"
          img-width="1024"
          img-height="240"
          style="text-shadow: 1px 1px 2px #333;"
        >
          <b-carousel-slide v-for="(item,index) in render.banner" :key="index" :img-src="item.path"></b-carousel-slide>
        </b-carousel>
      </div>
    </div>
    <div class="container">
      <div v-html="render.content"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let username = localStorage.getItem("username");
import Vue from "vue";
import axios from "axios";
import $ from "jquery";
export default {
  props: ["lang", "lanid"],
  data() {
    return {
      slide: 0,
      render: [],
    };
  },
  async created() {
    let res = await axios.post(Vue.prototype.baseURL + `Index/newsInfo`, {
      lang_type: this.lan,
      news_id: this.newsid,
    });
    console.log(res);
    this.render = res.data.data;
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
    newsid() {
      return this.$store.state.newsid;
    },
  },
  watch: {
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/newsInfo`, {
        lang_type: this.lan,
        news_id: this.newsid,
      });
      console.log(this.lan);
      console.log(this.newsid);
      this.render = res.data.data;
    },
    async newsid() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/newsInfo`, {
        lang_type: this.lan,
        news_id: this.newsid,
      });
      console.log(this.lan);
      console.log(this.newsid);
      this.render = res.data.data;
    },
  },
};
</script>

<style lang="scss">
#carousel-1 .carousel-indicators li {
  border-radius: 3px;
}
@media screen and (max-width: 800px) {
  .aboutus {
    line-height: 8vw !important;
    font-size: 2.5vw !important;
  }
  .showcompone {
    font-size: 3vw !important;
    line-height: 5vw !important;
    padding-top: 1vw;
    padding-bottom: 1vw;
    text-indent: 1.4vw;
  }
  .news_box{
    min-height:50vw;
  }
}
.news_img {
  width: 100%;
}
.showcompone {
  font-size: 0.7vw;
  line-height: 1.4vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  text-indent: 1.4vw;
}
.news_box {
  background: #f8f8f8;
}
.carousel-indicators li {
  width: 6px !important;
  height: 6px !important;
}
.bannerinfo {
  padding: 0 !important;
}
.carousel-indicators li {
  border: none !important;
}
.aboutus {
  line-height: 4.4vw;
  font-size: 1.4vw;
}
</style>