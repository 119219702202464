<template>
  <div class="detail_box">
    <div class="detail_nab common"></div>
    <div class="detail_info common">
      <div class="left_detailbox">
        <img :src="path" alt />
        <div class="imglist">
          <div
            v-for="(item, index) in goodsdetail.goods_info.goods_picture"
            :key="index"
          >
            <img
              :src="item.path"
              alt
              :class="checkimg == index ? 'checkimg' : ''"
              @click="checkimgf(index)"
            />
          </div>
        </div>
      </div>
      <div class="right_box">
        <div>{{ goodsdetail.goods_info.goods_name }}</div>
        <div
          class="right_bottom_box"
          v-html="goodsdetail.goods_info.goods_content"
        ></div>
      </div>
    </div>
    <div class="detail_media common" v-if="media_link != ''">
      <video
        :src="media_link"
        type="video/mp4"
        controls
        v-if="video_show"
      ></video>
      <div class="detail_media_info">
        <div
          @click="change_video"
          style="
            cursor: pointer;
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 1vw;
          "
        >
          {{ lan == 1 ? "观看相关视频" : "Watch Related videos" }}
          <div
            class="icon"
            :class="[video_show ? 'icontrans' : '']"
            style=""
          ></div>
        </div>
      </div>
    </div>
    <div class="table_box common">
      <div class="common ts">
        <b-alert v-model="issend" variant="success">{{ sucmsg }}</b-alert>
      </div>
      <div class="common ts">
        <b-alert v-model="failsend" variant="danger">{{ sucmsg }}</b-alert>
      </div>
      <div class="table_head">
        <div
          v-for="(item, index) in goodsdetail.goods_specification_list"
          :key="index"
        >
          {{ item.specification_name }}
        </div>
        <!-- <div>数量</div> -->
        <div>{{ lan == 1 ? "操作" : "Action" }}</div>
      </div>
      <div
        class="table_body"
        v-for="(item, index) in goodsdetail.product_list"
        :key="index"
      >
        <div v-for="(it, x) in item.specification_value_arr" :key="x">
          {{ it }}
        </div>
        <!-- <div class="infonumber">
          <input type="number" value="1" />
        </div>-->
        <div class="tocart" @click="tocart(item.product_id)">
          <div>{{ lan == 1 ? "加入购物车" : "Add to cart" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let users_id = localStorage.getItem("users_id");
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      issend: false,
      sucmsg: "",
      failsend: false,
      path: "",
      checkimg: 0,
      goodsdetail: {
        goods_info: {
          goods_name: "",
        },
      },
      media_link: "",
      video_show: false,
    };
  },
  methods: {
    change_video() {
      this.video_show = !this.video_show;
    },
    checkimgf(i) {
      this.checkimg = i;
      this.path = this.goodsdetail.goods_info.goods_picture[i].path;
    },
    async tocart(id) {
      // this.$router.push("/cart");
      console.log(id);
      let res = await axios.post(Vue.prototype.baseURL + `Index/addCart`, {
        users_id: users_id,
        product_id: id,
        lang_type: this.lan,
      });
      console.log(res);
      if (res.data.code == 1) {
        if (this.lan == 1) {
          alert("添加成功");
        }
        if (this.lan == 2) {
          alert("Success");
        }
        // this.issend = true;
        this.sucmsg = res.data.message;
        let that = this;
        setTimeout(function () {
          that.issend = false;
        }, 1500);
        location.reload();
      }
      if (res.data.code == -1) {
        this.failsend = true;
        let that = this;
        this.sucmsg = res.data.message;
        setTimeout(function () {
          that.failsend = false;
          that.$router.push("/re");
        }, 1500);
      }
    },
  },
  computed: {
    lan() {
      return this.$store.state.lan;
    },
  },
  async created() {
    this.$route.params.id;
    let res = await axios.post(Vue.prototype.baseURL + `Index/goodsInfo`, {
      lang_type: this.lan,
      goods_id: this.$route.params.id,
    });
    console.log(res);
    this.media_link = res.data.data.goods_info.media_link;
    console.log(this.media_link);
    this.goodsdetail = res.data.data;
    this.checkimgf(0);
  },
  watch: {
    async lan() {
      let res = await axios.post(Vue.prototype.baseURL + `Index/goodsInfo`, {
        lang_type: this.lan,
        goods_id: this.$route.params.id,
      });
      console.log(res);
      this.goodsdetail = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail_media_info {
  width: 100%;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  background: #666;
  display: flex;
}
.icon {
  height: 0;
  width: 0;
  border-top: 5px solid #ffffff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 10px;
  cursor: pointer;
}
.icontrans {
  transform: rotate(180deg);
}
.detail_media > video {
  width: 100%;
  height: 400px;
}
.detail_media {
  width: 100%;
  min-height: 30px;
  background: #666;
  margin-top: 30px;
}
.checkimg {
  border: 1px solid #018168 !important;
}
@media screen and (max-width: 800px) {
  .left_detailbox > img {
    height: 22.5vw !important;
  }
  .imglist > div > img {
    width: 15vw !important;
    height: 10vw !important;
  }
  .left_detailbox {
    width: 45vw !important;
  }
  .imglist {
    height: 10vw !important;
  }
  .table_head > div {
    flex: 1;
    text-align: center;
    line-height: 3.1vw;
    font-size: 2.5vw !important;
    color: #ffffff;
  }
  .table_body > div {
    border: 1px solid #dddddd;
    flex: 1;
    text-align: center;
    line-height: 4.6vw;
    font-size: 2.5vw;
    color: #333;
  }
  .right_bottom_box {
    line-height: 2.25vw !important;
  }
  .infonumber > input {
    display: block;
    width: 5vw;
    height: 3vw;
    margin: auto;
    text-align: center;
    margin-top: 0.6vw !important;
  }
  .detail_nab {
    line-height: 8vw !important;
    font-size: 3vw !important;
    color: #999999;
  }
  .right_box > div:nth-of-type(1) {
    line-height: 4vw !important;
    font-size: 2.5vw !important;
    font-weight: 600;
  }
  .detail_info > div:nth-of-type(2) {
    margin-left: 10.6vw !important;
  }
  .detail_info {
    min-height: 20vh !important;
    min-height: 3vw;
    display: flex;
    padding-left: 5.7vw;
  }
  .right_box > div:nth-of-type(2) {
    line-height: 5vw !important;
    font-size: 2.5vw !important;
    height: 37.5vw;
    overflow: auto;
  }
  .right_box > div:nth-of-type(2) {
    width: 33vw !important;
    font-size: 1vw;
    min-height: 23.5vw;
    padding: 0.78vw 1.05vw;
    background: #f8f8f8;
    border: 1px solid #ededed;
  }
}
.table_box {
  margin-bottom: 4vw;
}
.tocart {
}
.detail_box {
  padding: 3vw;
}
.tocart > div {
  cursor: pointer;
  // margin: 0.8vw auto 0.7vw auto;
  line-height: 3.1vw !important;
  width: 7vw;
  font-size: 1vw;
  color: #ffffff;
  background: #0e886f;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
.infonumber > input {
  display: block;
  width: 2.3vw;
  height: 1.47vw;
  margin: auto;
  text-align: center;
  margin-top: 1.6vw;
}
.table_body:nth-last-of-type(1) {
  margin-bottom: 4vw;
}
.table_body {
  display: flex;
}
.table_body > div {
  border: 1px solid #dddddd;
  flex: 1;
  text-align: center;
  // line-height: 4.6vw;
  padding-top: 1.8vw;
  padding-bottom: 1.8vw;
  font-size: 1vw;
  // vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}
.table_head {
  margin-top: 4vw;
  display: flex;
  width: 100%;
  background: #666666;
}
.table_head > div {
  flex: 1;
  text-align: center;
  line-height: 3.1vw;
  font-size: 1vw;
  color: #ffffff;
}
.right_bottom_box {
  // line-height: 1.25vw !important;
}
.right_bottom_box > div > .list-paddingleft-2 {
  width: 100% !important;
}
.right_bottom_box > div:nth-of-type(1) {
  font-size: 0.84vw;
  color: #9f9f9f;
  text-indent: 1.68vw;
}
.right_bottom_box > div:nth-of-type(2) {
  font-size: 0.84vw;
  text-indent: 1.68vw;
}
.right_bottom_box > div:nth-of-type(3) {
  font-size: 0.84vw;
  color: #9f9f9f;
  text-indent: 1.68vw;
}
.right_bottom_box > div:nth-of-type(4) > span {
  color: #333;
}
.right_bottom_box > div:nth-of-type(4) {
  font-size: 0.84vw;
  color: #9f9f9f;
  text-indent: 1.68vw;
}
.right_bottom_box > div:nth-of-type(5) > span {
  color: #333;
}
.right_bottom_box > div:nth-of-type(5) {
  font-size: 0.84vw;
  color: #9f9f9f;
  text-indent: 1.68vw;
}
.right_box > div:nth-of-type(1) {
  line-height: 2.8vw;
  font-size: 1.5vw;
  font-weight: 700;
}
.right_box > div:nth-of-type(2) {
  width: 43vw;
  font-size: 1vw;
  min-height: 23.5vw;
  padding: 0.78vw 1.05vw;
  background: #f8f8f8;
  border: 1px solid #ededed;
}
.imglist {
  display: flex;
  margin-top: 10px;
  width: 100%;
  overflow: auto;
}
.left_detailbox {
  width: 30vw;
}
.left_detailbox > img {
  width: 100%;
  height: 19.5vw;
  border: 1px solid #ededed;
}
.imglist > div > img {
  width: 9.3vw;
  height: 6.3vw;
  border: 1px solid #ededed;
}
.imglist > div {
  margin-left: 10px;

  height: 6.3vw;
  box-sizing: content-box !important;
}
.imglist > div:nth-of-type(1) {
  margin-left: 0px;
}
.detail_info {
  min-height: 51.4vh;
  display: flex;
}

.detail_info > div:nth-of-type(2) {
  margin-left: 3.6vw;
}

.detail_nab {
  line-height: 3.7vw;
  font-size: 1vw;
  color: #999999;
}
</style>